import styled from "styled-components"
import ItemLink from "./component"
import { colors } from "../../../utils"

export default styled(ItemLink)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 11.5px 0px;

    .link-wrapper {
        font-size: 16px;
        font-weight: bold;
        padding-left: 4px;
    }

    .icon-wrapper {
        padding-left: 8px;
        display: flex;
        align-items: center;
    }

    &.active {
        :before {
            content: "";
            display: block;
            height: 24px;
            width: 4px;
            background: ${colors.PrimaryMagenta100};
            border-radius: 12px;
        }
        .link-wrapper {
            color: ${colors.PrimaryMagenta100} !important;
        }

        .icon-wrapper {
            padding-left: 8px;
        }
    }
`
