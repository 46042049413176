import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { Dashboard } from "components/Layout";

import Loading from "pages/Loading";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import MyCompany from "pages/MyCompany";
import RecoverPassword from "pages/RecoverPassword";
import ListOfUsers from "pages/ListOfUsers";
import Invitations from "pages/Invitations";
import Modals from "modals";
import * as Icons from "components/Icons";
import domain from "appDomain";
import { routes } from "utils";
import { ToastContainer } from "components/Toast/";

const navigatorRoutes = [
  {
    routeName: "users",
    route: routes.users,
    Icon: Icons.Users,
    showIcon: true,
  },
  {
    routeName: "invitations",
    route: routes.invitations,
    Icon: Icons.Users,
    showIcon: true,
  },
  {
    routeName: "my_company",
    route: routes.my_company,
    Icon: Icons.Status,
    showIcon: true,
  },
];

const RedirectTo = ({ to }) => <Redirect to={to} />;

const Navigator = () => {
  const { signedIn, user, loading } = domain.auth.hooks.useSignedIn();

  if (loading) return <Loading />;

  return (
    <>
      {signedIn ? (
        <Router>
          <Dashboard
            Main={
              <Switch>
                <Route path="/users">
                  <ListOfUsers />
                </Route>
                <Route path="/invitations">
                  <Invitations />
                </Route>
                <Route path="/my-company">
                  <MyCompany />
                </Route>
                <Route>
                  <RedirectTo to="/users" />
                </Route>
              </Switch>
            }
            navRoutes={navigatorRoutes}
          />
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/register">
              <SignUp />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/recover">
              <RecoverPassword />
            </Route>
            <Route>
              <SignIn />
            </Route>
          </Switch>
        </Router>
      )}
      {signedIn && (
        <>
          <Modals />
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Navigator;
