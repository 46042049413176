import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const query = gql`
  {
    company_invitations_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const useGetUserCompanyNumberOfInvitations = () => {
  const [numberOfInvitations, setNumberOfInvitations] = useState(false);
  const {
    data,
    loading,
    errors,
    refetch: refreshCompanyQuery,
  } = useQuery(query, { fetchPolicy: "no-cache" });
  useEffect(() => {
    if (
      !loading &&
      data &&
      data.company_invitations_aggregate &&
      data.company_invitations_aggregate.aggregate &&
      data.company_invitations_aggregate.aggregate.count
    ) {
      setNumberOfInvitations(
        data.company_invitations_aggregate.aggregate.count
      );
    }
  }, [loading, data]);
  return { numberOfInvitations, refreshCompanyQuery, loading, errors };
};

export default useGetUserCompanyNumberOfInvitations;
