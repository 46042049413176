import styled from "styled-components";

import Loading from "./page";
import { colors } from "utils";

export default styled(Loading)`
  background: ${colors.PrimaryBlue150};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;
