import { Auth } from 'aws-amplify'
import config from 'config'

export default () => {
  const BASE_URL = config.BASE_URL

  const CONTENT_TYPES = {
    json: 'application/json',
    jpg: 'image/jpeg',
  }

  const _getToken = async () => {
    try {
      const { idToken = {} } = await Auth.currentSession()
      return (idToken && idToken.jwtToken) || false
    } catch (error) {
      throw error
    }
  }

  const fetchGeneric = async ({
    method,
    body,
    path,
    uri = false,
    contentType = '',
  }) => {
    const jwtToken = await _getToken()

    return await fetch(uri ? uri : `${BASE_URL}/${path}`, {
      method,
      mode: 'cors',
      ...( body ? {body: JSON.stringify(body)} : {}),
      headers: {
        'Content-Type': contentType || CONTENT_TYPES.json,
        authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      },
    })
  }

  const fetchWithBodyNoAuth = async ({
    method,
    body,
    path,
    uri = false,
    contentType = '',
  }) => {
    return await fetch(uri, {
      method,
      body,
      headers: {
        'Content-Type': contentType,
      },
    })
  }

  const get = async ({ ...params }) =>
  await fetchGeneric({
    method: 'GET',
    ...params,
  })

  const patch = async ({ ...params }) =>
    await fetchGeneric({
      method: 'PATCH',
      ...params,
    })

  const put = async ({ ...params }) =>
    await fetchGeneric({
      method: 'PUT',
      ...params,
    })

  const noAuthPut = async ({ ...params }) =>
    await fetchWithBodyNoAuth({
      method: 'PUT',
      ...params,
    })

  const post = async ({ ...params }) =>
    await fetchGeneric({
      method: 'POST',
      ...params,
    })

  return {
    get,
    patch,
    put,
    post,
    noAuthPut,
  }
}
