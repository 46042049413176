import { useState, useEffect, useCallback } from "react";

const DEFAULT_PAGE_SIZES = [5, 10, 20, 25, 50, 100];

export const usePagination = ({ numItems, baseConfig, rowText = "rows" }) => {
  const pageSizeOptions = DEFAULT_PAGE_SIZES.map((value, index) => ({
    key: index,
    value,
    label: `${value} ${rowText}`,
  }));
  const { limit: initialPageSize, offset: initialOffset } = baseConfig;
  const [pageSize, setPageSize] = useState(Number(initialPageSize || 10));
  const [offset, setOffset] = useState(Number(initialOffset || 0));
  const [currentPageText, setCurrentPageText] = useState(undefined);

  useEffect(() => {
    setPageSize(Number(initialPageSize || 10));
  }, [initialPageSize]);

  useEffect(() => {
    setOffset(Number(initialOffset || 0));
  }, [initialOffset]);

  useEffect(() => {
    if (offset > 0) {
      setOffset(0);
    }

    if (initialPageSize === -1) {
      setPageSize(
        DEFAULT_PAGE_SIZES.indexOf(initialPageSize) >= 0
          ? initialPageSize
          : pageSizeOptions[1].value
      );
    }
  }, [numItems]);

  const computeNewOffset = useCallback(
    (newOffset) => {
      const computedOffset = Math.max(
        Math.min(newOffset, Math.floor(numItems / pageSize) * pageSize),
        0
      );
      setCurrentPageText(String(Math.floor(computedOffset / pageSize) + 1));
      setOffset(computedOffset);
    },
    [numItems, pageSize]
  );

  const handleNext = useCallback(() => computeNewOffset(offset + pageSize), [
    offset,
    pageSize,
    computeNewOffset,
  ]);
  const handlePrev = useCallback(() => computeNewOffset(offset - pageSize), [
    offset,
    pageSize,
    computeNewOffset,
  ]);
  const handleSetPageSize = useCallback(
    ({ value }) => setPageSize(Number(value)),
    []
  );

  const currentPage = Math.floor(offset / pageSize) + 1;
  const maxPages = Math.ceil(numItems / pageSize);

  const handleSetCurrentPage = useCallback(
    ({ value }) => {
      setCurrentPageText(value);
      if (/^\d+$/.test(value)) {
        computeNewOffset((value - 1) * pageSize);
      }
    },
    [computeNewOffset, pageSize]
  );

  const paginationValues = {
    ...(pageSize ? { limit: pageSize } : {}),
    ...(offset ? { offset: offset } : {}),
  };

  const paginationActions = {
    next: handleNext,
    prev: handlePrev,
    setCurrentPage: handleSetCurrentPage,
    setPageSize: handleSetPageSize,
  };

  return {
    numItems: numItems,
    currentPage,
    currentPageText:
      currentPageText !== undefined ? currentPageText : currentPage,
    maxPages,
    pageSize,
    offset,
    paginationValues,
    paginationActions,
    pageSizeOptions,
  };
};

export const countFromAggregation = ({ data, baseConfig }) =>
  data?.[`${baseConfig.entity}_aggregate`]?.aggregate?.count || 0;
