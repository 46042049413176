import React from "react";
import { InputSelect, Input } from "components/FormComponents";
import Button from "../Button";
import { ArrowLeft, ArrowRight } from "components/Icons";
import { colors } from "utils";
import { useTranslation } from "react-i18next";

const Pagination = ({ className: styledClassName = "", pagination = {} }) => {
  const {
    numItems,
    maxPages,
    currentPageText,
    paginationActions,
    pageSize,
    pageSizeOptions,
  } = pagination;

  const { t } = useTranslation();

  const sizeOptionsTranslated = (pageSizeOptions || []).map((op) => ({
    ...op,
    label: t("Pagination:row", { count: op.value }),
  }));

  return (
    <div className={`${styledClassName} pagination-container`}>
      <div className="pagination-wrapper">
        <Button
          flat
          label={<ArrowLeft currentcolor={colors.PrimaryMagenta100} />}
          onClick={paginationActions.prev}
          className="button button-prev"
        />

        <div className="input-current-page-container">
          <Input
            className="input-current-page"
            value={currentPageText}
            isControlled={true}
            onChange={paginationActions.setCurrentPage}
          />
          <span>-</span>
          <span>{maxPages}</span>
        </div>

        <Button
          flat
          label={<ArrowRight currentcolor={colors.PrimaryMagenta100} />}
          onClick={paginationActions.next}
          className="button button-next"
        />
      </div>

      <div className="filter-container">
        <InputSelect
          className="input-select-num-items"
          value={pageSize}
          isControlled={true}
          onChange={paginationActions.setPageSize}
          options={sizeOptionsTranslated}
        />
        <span className="num-items-indicator">
          {t("Pagination:results", { count: numItems })}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
