import * as React from "react";

function SvgEyeClose(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.87.278a.428.428 0 00-.588-.164l-.033.017A17.922 17.922 0 0111.788 1.6c-1.192.36-2.417.571-3.625.571-1.208 0-2.432-.212-3.608-.587A17.922 17.922 0 011.094.114L1.06.098a.426.426 0 00-.539.098.44.44 0 00.033.62c.474.408.963.784 1.486 1.094L1.19 3.43a.689.689 0 00.589 1.028.671.671 0 00.587-.343l.866-1.518c.277.13.555.261.849.375.196.082.391.147.587.213V3.2l-.457 1.682a.68.68 0 00.474.832h.18a.677.677 0 00.652-.506l.458-1.681c.506.097 1.012.18 1.534.212v1.73c0 .376.31.67.67.67.359 0 .669-.31.669-.67V3.74a11.334 11.334 0 001.518-.212v.016l.457 1.681c.082.31.36.507.654.507.065 0 .114 0 .18-.017a.68.68 0 00.473-.832L11.673 3.2v-.016c.196-.066.392-.147.588-.213.278-.114.555-.244.833-.375v.016l.865 1.519c.13.212.36.342.588.342a.686.686 0 00.343-.097.67.67 0 00.245-.931l-.866-1.502A11.99 11.99 0 0015.755.849c.163-.163.212-.392.114-.571z"
        fill="#A6A9B4"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgEyeClose;
