import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
/*import { Main } from "components/Layout";
import PanelCompanyUser from "./components/PanelCompanyUser";
import MainCompanyUser from "./components/MainCompanyUser";
import ImportUsersForm from "components/ImportUsersForm";*/

import InvitationsList from "./components/InvitationsList";

/*const UserList = ({ className: styledClassName }) => {
  return (
    <div className={`${styledClassName} page`}>
      <Main>
        <PanelCompanyUser className="page-aside" />
        <MainCompanyUser className="page-section" />
      </Main>
    </div>
  );
};*/

const Invitations = ({ className: styledClassName }) => {
  let match = useRouteMatch();

  return (
    <div className={styledClassName}>
      <InvitationsList />
    </div>
  );
};

export default Invitations;
