import SearchBar from "./component";
import styled from "styled-components";
import { colors } from "utils";

export default styled(SearchBar)`
  .searchbar {
  }

  .searchbar-wrapper {
    position: relative;
    margin-bottom: 24px;
  }

  .searchbar-button {
    background: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
  }

  .simple-input {
    width: 100%;
  }
`;
