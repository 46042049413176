import styled from "styled-components";
import MailInput from "./component";
import { colors } from "utils";

export default styled(MailInput)`
  display: flex;
  flex-direction: row;
  margin-bottom: 2.5px;
  justify-content: center;
  align-items: center;

  .input-holder {
    flex: 1 0;

    input {
      flex: 1 0;
      font-size: 16px;
      padding: 4px 8px;
      :disabled {
        border: 1px solid;
        border-color: ${colors.SecondarySuccess150};
      }
    }
  }

  button {
    flex: 0 1;
    height: 1ch;
  }
`;
