import React, { useEffect, useState, useRef } from "react";
import { Toast } from "components/Toast";
import { render } from "react-dom";

const useCustomToast = ({
  autoClose = false,
  onCloseToast = null,
  onOpenToast = null,
} = {}) => {
  const [isToastAttached, setIsToastAttached] = useState(false);

  const handleCloseToast = () => {
    let container = document.getElementById("toast-container");
    if (container) {
      render(<></>, container);
      setIsToastAttached(false);
      onCloseToast?.();
    }
  };

  const notify = ({
    CustomComponent = null,
    title = null,
    message = null,
    type = null,
  } = {}) => {
    const container = document.getElementById("toast-container");
    if (container) {
      render(
        CustomComponent != null ? (
          <CustomComponent
            onCloseToast={handleCloseToast}
            onOpenToast={onOpenToast?.()}
            title={title}
            message={message}
          />
        ) : (
          <Toast
            onCloseToast={handleCloseToast}
            onOpenToast={onOpenToast?.()}
            title={title}
            message={message}
            type={type}
          />
        ),
        container
      );
      setIsToastAttached(true);
    }
  };

  useEffect(() => {
    let timer = null;

    if (typeof autoClose === "number" && isToastAttached) {
      timer = setTimeout(() => {
        handleCloseToast();
      }, autoClose);
    }

    return () => timer && clearTimeout(timer);
  }, [isToastAttached, autoClose]);

  return {
    notify,
  };
};

export default useCustomToast;
