import React, { useState, useEffect, useCallback } from "react";
import {
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import CompanyUserDetail from "../CompanyUserDetail";

const MainCompanyUser = ({ className: styledClassName = "" }) => {
  const { url } = useRouteMatch();
  return (
    <div className={`${styledClassName} main-company-user-container`}>
      <Switch>
        <Route path={`${url}/:id`}>
          <CompanyUserDetail userId={url} />
        </Route>
      </Switch>
    </div>
  );
};

export default MainCompanyUser;