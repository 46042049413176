import * as React from "react";

function SvgArrowLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.currentColor || "#5E698A"}
          fillRule="nonzero"
          d="M15.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 00-.03 1.383l5.5 6a1 1 0 001.474-1.352l-4.853-5.294 5.323-5.323z"
        />
      </g>
    </svg>
  );
}

export default SvgArrowLeft;
