import React from "react"
import { Link } from "react-router-dom"
import { colors } from "utils"

const ItemLink = ({
    className: styledClassName,
    route,
    routeName,
    Icon = null,
    showIcon = true,
    isActive,
    onClick = null,
    ...props
}) => {
    return (
        <Link to={onClick ? null : route} onClick={onClick} className={`${styledClassName} item-link-container ${isActive ? "active" : ""}`} {...props}>
            {showIcon && Icon && (
                <span className="icon-wrapper">
                    <Icon {...( isActive ? {currentcolor: colors.PrimaryMagenta100} : {})} />
                </span>
            )}
            <span className="link-wrapper">
                {routeName}
            </span>
        </Link>
    )
}

export default ItemLink
