import styled from "styled-components";
import media from "styled-media-query";

import SignIn from "./page";
import { colors } from "utils";

export default styled(SignIn)`
  background: ${colors.PrimaryBlue150};
  min-height: 100vh;
  display: flex;

  .login_modal_container {
    position: relative;
    background-color: white;
    padding: 32px;
    text-align: left;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    min-height: 350px;
    margin: auto;

    display: flex;
    flex-direction: column;

    ${media.lessThan("small")`
        height: 100vh;
        width: 100vw;
        max-width: unset;
        border-radius: 0;
    `}

    .wannalisn_logo {
      height: inherit;
      width: 100%;
      ${media.lessThan("small")`
          height: auto;
          width: inherit;
          max-width: 80vw;
          `}
    }
    .logo_label {
      text-align: right;
      font-weight: 400;
      color: ${colors.SecondaryGrey50};
      opacity: 0.5;
    }

    .input-holder {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }

    .login__forgot {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 24px;
      margin-top: 4px;
      a {
        color: ${colors.SecondaryGrey50};
      }
    }

    .login__new {
      margin-top: 24px;
      color: ${colors.SecondaryGrey40};

      a {
        color: ${colors.PrimaryMagenta100};
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
`;
