import { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const useGetUserCompanyUsersWithRole = (roleHumanId) => {
  const queryToExec = gql`
    {
      companies {
        id
        company_name
        administrative_code
        stripe_customer_id
        company_users ${
          roleHumanId
            ? `(where: {role: {role_human_id: {_eq: "${roleHumanId}"}}})`
            : ""
        }{
          id
          role_id
        }
      }
    }
    `;

  const [users, setUserList] = useState([]);
  const [company, setCompany] = useState({});
  const [refreshUserList, { data, loading, errors }] = useLazyQuery(
    queryToExec
  );
  useEffect(() => {
    if (!loading && data !== undefined && data && data.companies.length > 0) {
      console.log("data", data);
      const company = data.companies[0];
      if (company) {
        setUserList(company.company_users);
        setCompany(company);
      }
    }
  }, [loading, data]);
  useEffect(() => {
    refreshUserList();
  }, []);

  console.log("company", errors);
  return { company, users, refreshUserList };
};

export default useGetUserCompanyUsersWithRole;
