import styled from "styled-components";
import ImportUsersForm from "./component";
import { colors } from "utils";

export default styled(ImportUsersForm)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 10px;
  padding: 16px;
  color: ${colors.PrimaryBlack};
  max-width: min(100vw, 720px);
  margin: 32px auto;

  .import_users_header {
    margin-bottom: 16px;
    h1 {
      font-size: 32px;
      color: ${colors.PrimaryBlue150};
      margin-bottom: 5px;
    }
    ,
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
    }
    a {
      text-decoration: none;
      color: #ff3d61;
      font-weight: bold;
      &:hover {
        opacity: 0.8;
        text-decoration: underline;
      }
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .primary {
      width: 100%;
    }
    a {
      text-decoration: none;
      padding: 16px;
      opacity: 0.5;
    }
  }
`;
