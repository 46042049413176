import React from "react"
import MarkdownIt from "markdown-it"
import MdEditor from "react-markdown-editor-lite"
import "react-markdown-editor-lite/lib/index.css"

const mdParser = new MarkdownIt(/* Markdown-it options */)

const InputMd = ({
    className: stylesClassName,
    initialvalue,
    onChange,
    label,
}) => {
    return (
        <div className={stylesClassName}>
            <div className="input-holder">
                <span className="label"> {label}: </span>
                <div className="mi-editor">
                    <MdEditor
                        value={initialvalue}
                        renderHTML={(text) => mdParser.render(text)}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default InputMd
