import React, { useState } from "react"
import { SideBar } from "../../SideBar"
import { ArrowLeft, ArrowRight } from "../../Icons"

const SideBarLayout = ({
    className: styledClassName,
    navRoutes = [],
    children,
}) => {
    const [isSideBarExpanded, setIsSideBarExpanded] = useState(true)

    //TODO: for this action this should have something like a hamburguer button.
    const handleSideBarVisibility = () => {
        setIsSideBarExpanded(!isSideBarExpanded)
    }

    return (
        <div
            className={`${styledClassName} ${
                isSideBarExpanded ? "sidebar-expanded" : "sidebar-collapsed"
            }`}
        >
            <div className="sidebar-container">
                <SideBar navRoutes={navRoutes} />
                <button
                    className={`${styledClassName} toggle-button`}
                    onClick={handleSideBarVisibility}
                >
                    {isSideBarExpanded ? <ArrowLeft /> : <ArrowRight />}
                </button>
            </div>

            <div className="sidebar-content">{children}</div>
        </div>
    )
}

export default SideBarLayout
