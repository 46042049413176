import * as React from "react";

function SvgEye(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-3-6h24v24H-3z" />
        <g fill={props.currentColor || "#5E698A"} opacity={0.3}>
          <path
            d="M0 6s2.455-6 9-6c4.91 0 9 6 9 6s-4.09 6-9 6c-6.545 0-9-6-9-6z"
            fillRule="nonzero"
          />
          <path fill="#eee" d="M9 9a3 3 0 110-6 3 3 0 010 6z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgEye;
