import styled from "styled-components";
import MailCSVImportForm from "./component";
import { colors } from "utils";

export default styled(MailCSVImportForm)`
  display: flex;
  flex-direction: row;
  margin: 24px 0px;

  .import_list_mails_container {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
    flex: 1;

    .import_list_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      button {
        padding: 0;

        span {
          font-size: 12px;
          color: ${colors.SecondaryGrey40};
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    h1 {
      font-size: 18px;
    }

    .import_list_mails {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 4px;
      margin-botton: 4px;
      min-height: 15ch;
      span {
        width: 2.5ch;
      }
    }
  }
  .import_list_input_drop_container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: right;
    flex: 1;

    .import_list_dropzone {
      border-radius: 8px;
      border: dashed 1px ${colors.SecondaryGrey40};
      .drop-zone {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        border-radius: 8px;

        img {
          width: min-content;
          margin: 1rem;
        }

        p {
          margin-bottom: 8px;
          text-align: center;
        }

        button {
          .label {
            font-size: 12px;
            color: ${colors.SecondaryGrey40};
          }
        }

        &:hover {
          opacity: 0.8;
          background: ${colors.PrimaryBlue10};
          border-radius: 8px;

          button {
            .label {
              font-size: 12px;
              color: ${colors.SecondaryGrey50};
            }
          }
        }
      }
      a {
        text-decoration: none;
        color: ${colors.SecondaryGrey40};
        font-weight: bold;
      }
    }
  }

  .template {
    a {
      text-decoration: none;
      color: ${colors.SecondaryGrey40};
      font-weight: bold;
      font-size: 12px;
      margin-top: 8px;

      &:hover {
        text-decoration: underline;
        opacity: 0.9;
      }
    }
  }

  .pagination-container {
    button {
      padding: 0;
    }
  }
`;
