export const errorsByCode = {
  UserNotConfirmedException: "UserNotConfirmedException",
  EmailNotValidException: "EmailNotValidException",
  UsernameNotValidException: "UsernameNotValidException",
  PasswordlNotValidException: "PasswordlNotValidException",
  TermsNotValidException: "TermsNotValidException",
  PasswordConfirmationNotValidException:
    "PasswordConfirmationNotValidException",
  PasswordResetRequiredException: "PasswordResetRequiredException",
  UsernameExistsException: "UsernameExistsException",
  NotAuthorizedException: "NotAuthorizedException",
  UserNotFoundException: "UserNotFoundException",
  PasswordsShouldMatch: "PasswordsShouldMatch",
  InvalidPasswordException: "InvalidPasswordException",
  CodeMismatchException: "CodeMismatchException",
  InvalidParameterException: "InvalidParameterException",
  UserNotFoundException: "UserNotFoundException",
  LimitExceededException: "LimitExceededException",
  InvalidField: "InvalidField",
  InvalidPhoneNumber: "InvalidPhoneNumber",
  ExpiredCodeException: "ExpiredCodeException",
};

export const getErrorByCode = (errCode) =>
  (errCode && errorsByCode[errCode]) || errorsByCode[`InvalidField`];

export const isEmailValid = (email) => {
  const reg = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return reg.test(email);
};

export const isPhoneNumberValid = (number) => {
  const reg = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
  return reg.test(number);
};

export const isStringAndNotNull = (str) =>
  typeof str === "string" && !(str === "");

export const removeErrorFromErrors = ({ errors, errorKey }) =>
  Object.keys(errors).reduce(
    (newErrors, key) =>
      key !== errorKey ? { ...newErrors, [key]: errors[key] } : newErrors,
    {}
  );

export const isPasswordValid = (password) => password?.length >= 8;

export const validateValues = (validations) => (value) =>
  (validations || [])
    .map((validation) => validation(value))
    .every((isValid) => isValid);

export const ValidateForm = (itemsWithValidations) => (formData) => {
  const errors = Object.keys(formData)
    .map((keyToValidate) => {
      const { validations = null, errorKey = null } =
        itemsWithValidations[keyToValidate] || {};
      return validateValues(validations)(formData[keyToValidate])
        ? false
        : { [keyToValidate]: errorKey };
    })
    .filter((errorKey) => errorKey !== false)
    .reduce((errors, current) => ({ ...errors, ...current }), {});

  return { isValid: Object.keys(errors).length <= 0, errors };
};
