import React from "react";

const Input = ({
  className: styledClassName,
  onChange,
  name,
  label,
  placeholder,
  onKeyUp,
  showLabel = true,
  value = "",
  isControlled = false,
  error = false,
  ref,
  type = "text",
  ...props
}) => {
  return (
    <div
      className={`${styledClassName} input-holder ${
        error ? "input-error" : ""
      }`}
    >
      {showLabel && label && (
        <span accessibilitylabel={label} className="label">
          {" "}
          {label}{" "}
        </span>
      )}
      <input
        className="simple-input"
        defaultValue={value}
        placeholder={placeholder || label}
        onKeyUp={onKeyUp}
        onChange={(e) =>
          onChange && onChange({ name, value: e.target.value }, e)
        }
        name={name}
        {...(isControlled ? { value } : {})}
        type={type}
        {...props}
        autoComplete="off"
      />

      {error && (
        <div>
          <p className="error_feedback">{error}</p>
        </div>
      )}
    </div>
  );
};

export default Input;
