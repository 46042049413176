import styled from "styled-components";
import Pagination from "./component";
import { colors } from "utils";

export default styled(Pagination)`
  padding: 16px 8px;
  .pagination-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
  }

  .filter-container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    padding-top: 4px;

    .num-items-indicator {
      text-align: right;
      font-size: 12px;
      color: ${colors.SecondaryGrey50};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .input-current-page-container {
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .input-current-page {
      width: 24px;
      display: inline-block;
      padding: 0px;
      .simple-input {
        display: inline-block;
        background: ${colors.SecondaryGrey20};
        border-radius: 4px;
        padding: 4px;
        font-size: 16px;
        margin-bottom: -1px;
        text-align: center;
        max-width: 100%;
        box-sizing: border-box;

        :focus {
          border: 1px solid ${colors.SecondaryGrey50};
        }
      }
    }

    span {
      font-size: 16px;
      margin: 0px 8px;
    }
  }

  .button {
    width: 60px;
    transition: 100ms all ease-in-out;
    &.button-next {
      justify-self: end;
    }
    &.button-prev {
      justify-self: start;
    }

    &:hover {
      background: ${colors.SecondaryGrey30};
    }
  }
`;
