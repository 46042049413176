const auth = {
  commonAuthView: (properties) => ({
    type: "User Authentication started",
    properties,
  }),
  signupView: (properties) => ({
    type: "Company admin Signup started",
    properties,
  }),
  signupError: (properties) => ({
    type: "Company admin Signup error",
    properties,
  }),
  signupSubmitted: (properties) => ({
    type: "Company admin Signup submitted",
    properties,
  }),
  signupContactSubmitted: (properties) => ({
    type: "Company admin Signup contact info submitted",
    properties,
  }),
  signupSucceed: (properties) => ({
    type: "Company admin Signup succeed",
    properties,
  }),
  loginView: (properties) => ({
    type: "User Login started",
    properties,
  }),
  loginError: (properties) => ({
    type: "User Login error",
    properties,
  }),
  loginSubmitted: (properties) => ({
    type: "User Login submitted",
    properties,
  }),
  loginSucceed: (properties) => ({
    type: "User Login succeed",
    properties,
  }),
  forgotView: (properties) => ({
    // not requested
    type: "User forgot password started",
    properties,
  }),
  changePasswordView: (properties) => ({
    // not requested
    type: "User change password form started",
    properties,
  }),
};

export default auth;
