import React, { useEffect, useState } from "react";
import domain from "appDomain";
import {
  Input,
  InputCheck,
  FormLoading,
  PasswordInput,
} from "components/FormComponents";
import Button from "components/Button";
import Terms from "components/Terms";
import analytics from "../../analytics";

import { Trans, useTranslation } from "react-i18next";
import CallMe from "components/CallMe";
import { InputPhone } from "components/FormComponents";
import { KEY_CODE, useOnKeyPressListener } from "utils";
import logo from "../SignIn/wannalisn_logo.png";

import Loading from "pages/Loading";
import { Link } from "react-router-dom";

import GoBack from "components/GoBack";

const UserAccountForm = ({
  isValid,
  validate,
  handleChange,
  errors,
  values: { username, password, telephone, contactName },
}) => {
  const { t, i18n } = useTranslation();

  const [isPreview, setPreview] = useState(false);

  const {
    username: usernameError,
    password: passwordError,
    telephone: telephoneError,
    contactName: contactNameError,
  } = errors;

  useEffect(() => {
    setPreview(isValid);
  }, [isValid]);

  const handleClickButton = () => {
    validate();
    setPreview(isValid);
  };

  return (
    <div className="login_modal_container">
      <div className="form-step">
        <p>1/2</p>
      </div>
      <div className="brand-container">
        <img className="wannalisn_logo" src={logo} alt="wannalisn" />
        <span className="logo_label">Business</span>
      </div>
      <div className="form-title">
        <h2>{t("Auth:create_account")}</h2>
      </div>
      {isPreview && isValid ? (
        <>
          <p className="user-contact-name">{contactName}</p>
          <p className="user-email">{username}</p>
          <p className="user-telephone">{telephone}</p>

          <span className="modify-button" onClick={() => setPreview(false)}>
            {t("Auth:modify_form")}
          </span>
        </>
      ) : (
        <>
          <Input
            autoFocus={true}
            showLabel={true}
            label={t("Auth:email_address")}
            placeholder={t("Auth:email_address")}
            type="email"
            name="username"
            onChange={handleChange}
            error={usernameError && t(`Errors:${usernameError}`)}
            value={username}
          />
          <PasswordInput
            showLabel={true}
            label={t("Auth:password")}
            placeholder={t("Auth:password")}
            type="password"
            name="password"
            onChange={handleChange}
            error={passwordError && t(`Errors:${passwordError}`)}
            value={password}
          />
          <InputPhone
            showLabel={true}
            label={t("Auth:phone")}
            placeholder={t("Auth:phone")}
            name="telephone"
            onChange={handleChange}
            error={telephoneError && t(`Errors:${telephoneError}`)}
            value={telephone}
          />
          <Input
            showLabel={true}
            label={t("Auth:contact_name")}
            placeholder={t("Auth:contact_name")}
            type="text"
            name="contactName"
            onChange={handleChange}
            error={contactNameError && t(`Errors:${contactNameError}`)}
            value={contactName}
          />

          <Button
            className="form-button"
            label={isValid ? t("Auth:save") : t("Auth:start")}
            type="primary"
            onClick={handleClickButton}
          />
        </>
      )}
    </div>
  );
};

const CompanyInfoForm = ({
  handleChange,
  isValid,
  validate,
  errors = {},
  handleTermsState,
  isDisabled = true,
  values: {
    administrativeCode,
    billingEmail,
    companyName,
    communications,
    terms,
    username,
  },
}) => {
  const [isPreview, setPreview] = useState(false);

  const { t, i18n } = useTranslation();

  const {
    companyName: companyNameError,
    administrativeCode: administrativeCodeError,
    billingEmail: billingEmailError,
    terms: termsError,
  } = errors;

  useEffect(() => {
    setPreview(isValid);
  }, [isValid]);

  const handleClickButton = () => {
    validate();
    setPreview(isValid);
  };

  return (
    <div className={`login_modal_container ${isDisabled ? "disabled" : ""}`}>
      <div className="form-step">
        <p>2/2</p>
      </div>
      <div className="form-title">
        <h2>{t("Auth:company_info")}</h2>
      </div>
      {isPreview && isValid ? (
        <>
          <p>
            <strong>{t("Auth:companay_name")}</strong> {companyName}
          </p>
          <p>
            <strong>{t("Auth:administrative_code")}</strong>{" "}
            {administrativeCode}
          </p>
          <p>
            <strong>{t("Auth:billing_email")}</strong> {billingEmail}
          </p>
          <p className="modify-button" onClick={() => setPreview(false)}>
            {t("Auth:modify_form")}
          </p>
        </>
      ) : (
        <>
          {!isDisabled && (
            <>
              <Input
                autoFocus={true}
                showLabel={true}
                label={t("Auth:companay_name")}
                placeholder={t("Auth:companay_name")}
                type="text"
                name="companyName"
                onChange={handleChange}
                error={companyNameError && t(`Errors:${companyNameError}`)}
                value={companyName}
              />
              <Input
                showLabel={true}
                label={t("Auth:administrative_code")}
                placeholder={t("Auth:administrative_code")}
                type="text"
                name="administrativeCode"
                onChange={handleChange}
                error={
                  administrativeCodeError &&
                  t(`Errors:${administrativeCodeError}`)
                }
                value={administrativeCode}
              />

              <Input
                autoFocus={true}
                showLabel={true}
                label={t("Auth:billing_email")}
                placeholder={t("Auth:billing_email")}
                type="email"
                name="billingEmail"
                onChange={handleChange}
                error={billingEmailError && t(`Errors:${billingEmailError}`)}
                value={billingEmail || username}
              />

              <InputCheck
                name="terms"
                label={
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => handleTermsState(true)}
                  >
                    {t("Auth:accept_terms")}
                  </span>
                }
                onChange={handleChange}
                error={termsError && t(`Errors:${errors.terms}`)}
                checked={terms}
              />
              <InputCheck
                name="communications"
                label={t("Auth:accept_communications")}
                onChange={handleChange}
                checked={communications}
              />

              <Button
                className="form-button"
                label={isValid ? t("Auth:save") : t("Auth:create_account")}
                type="primary"
                onClick={handleClickButton}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const AskForCodeForm = ({ handleSubmit, handleChange, loading, errors }) => {
  const { code: codeError, request: requestError } = errors;

  const { t } = useTranslation();

  return (
    <div className="login_modal_container">
      <div className="code">
        <Trans i18nKey="Auth:code_sent_notify_message" />
      </div>
      {requestError && (
        <div className="form_feedback">
          <p>{t(`Errors:${requestError}`)}</p>
        </div>
      )}
      <Input
        autoFocus={true}
        showLabel={true}
        label={t("Auth:code")}
        placeholder={t("Auth:code")}
        type="text"
        name="code"
        onChange={handleChange}
        error={codeError && t(`Errors:${codeError}`)}
      />
      <Button
        className="form-button-create-account form-button"
        label={t("Auth:recovery_account")}
        type="primary"
        onClick={handleSubmit}
        disabled={loading}
      />
    </div>
  );
};

const SignUp = ({ className: styledClassName }) => {
  const {
    values,
    errors,
    loading,
    waitingForCode,
    handleSubmit,
    handleChange,
    validateUserData,
    validateCompanyData,
    isCompanyFormValid,
    isUserFormValid,
  } = domain.auth.hooks.useSignUp();

  const [termsOpen, handleTermsState] = useState(false);

  useEffect(() => {
    analytics.methods.track(analytics.events.auth.signupView());
    // console.log("SignUpView event sent")
  }, []);

  const pressKeyAction = isUserFormValid
    ? isCompanyFormValid
      ? handleSubmit
      : validateCompanyData
    : validateUserData;

  useOnKeyPressListener({
    expectedKeys: [KEY_CODE.ENTER],
    action: pressKeyAction,
  });

  if (loading) return <Loading />;

  return (
    <div className={styledClassName}>
      <GoBack />
      <div className="forms-container">
        {waitingForCode ? (
          <AskForCodeForm
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        ) : (
          <>
            <UserAccountForm
              values={values}
              errors={errors}
              validate={validateUserData}
              handleChange={handleChange}
              handleSubmit={handleChange}
              isValid={isUserFormValid}
            />
            <CompanyInfoForm
              values={values}
              errors={errors}
              validate={validateCompanyData}
              handleChange={handleChange}
              handleTermsState={handleTermsState}
              isValid={isCompanyFormValid}
              isDisabled={!isUserFormValid}
            />
          </>
        )}
      </div>

      <CallMe />
      <Terms modalIsOpen={termsOpen} handleModalState={handleTermsState} />
    </div>
  );
};

export default SignUp;
