import auth from "./auth";
import company from "./company";
import invites from "./invites";
import users from "./users";
import utils from "./utils";

export default {
  auth,
  company,
  invites,
  users,
  utils,
};
