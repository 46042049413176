import React from "react";
import Loader from "components/Loader";

const Page = ({ className: styledClassName }) => {
  return (
    <div className={styledClassName}>
      <div className="loader-inner-container">
        <img
          className="wannalisn_logo"
          src={"/assets/images/wannalisn-business-white.png"}
          alt="wannalisn"
        />
        <Loader />
      </div>
    </div>
  );
};

export default Page;
