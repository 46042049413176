import * as React from "react";

function SvgLogout(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M14.001 7a1 1 0 102 0V6a4 4 0 00-4-4H6.005a3.998 3.998 0 00-3.998 4v12c0 2.21 1.79 4 3.998 4h6.004a3.998 3.998 0 003.998-4v-1a1 1 0 00-2 0v1c0 1.105-.894 2-1.998 2H6.005a2 2 0 01-1.999-2V6a2 2 0 012-2h5.995A2 2 0 0114 6v1z"
          opacity={0.3}
        />
        <rect
          width={2}
          height={12}
          x={13}
          y={6}
          fill={props.currentColor || "#FFF"}
          opacity={0.3}
          rx={1}
          transform="rotate(90 14 12)"
        />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M17.293 9.707a1 1 0 011.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L19.586 12l-2.293-2.293z"
        />
      </g>
    </svg>
  );
}

export default SvgLogout;
