import * as React from "react";

function SvgUsers(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M18 8a3 3 0 110 6 3 3 0 010-6zM9 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
          opacity={0.299}
        />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M17.6 15h-.112c3.458 0 6.299 1.72 6.51 5.4l.001.087c-.007.188-.084.513-.543.513H19.6a9.954 9.954 0 00-2-6zm-8.617-2c4.696 0 8.57 2.206 8.994 6.92l.02.28c.013.195 0 .8-.75.8H.727c-.25 0-.747-.54-.726-.8.387-4.773 4.26-7.2 8.982-7.2z"
        />
      </g>
    </svg>
  );
}

export default SvgUsers;
