import * as React from "react";

function SvgStatus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.currentColor || "#FFF"}
          d="M18.5 6A1.5 1.5 0 0120 7.5v11a1.5 1.5 0 01-3 0v-11A1.5 1.5 0 0118.5 6zm-6 5a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5zm-6 4A1.5 1.5 0 018 16.5v2a1.5 1.5 0 01-3 0v-2A1.5 1.5 0 016.5 15z"
        />
      </g>
    </svg>
  );
}

export default SvgStatus;
