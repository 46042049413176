import styled from "styled-components";
import InputPhone from "./component";
import { colors } from "../../../utils";

export default styled(InputPhone)`
  text-align: left;
  padding-top: 20px;

  .special-label {
    width: 100%;
    display: block;
    color: ${colors.PrimaryBlack};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
  }

  .flag-input {
    height: 100%;
  }

  .form-control:focus {
    border-color: none;
    box-shadow: none;
}

  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 6px;

    :focus {
      outline: none !important;
      border:none !important;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.SecondaryGrey40};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.SecondaryGrey40};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.SecondaryGrey40};
    }
  }

  .special-label {
    top: -24px;
    align-items: left:
    justify: left;
    z-index: 0;
  }
  .flag-input {
    height: 40vh;
    border-radius: 16px;
  }

  .simple-input {
    vertical-align: middle;
    padding-top: 16px;
    display: block;
    text-indent: 50px;
    width: 100%;
    border: none;
    background: ${colors.SecondaryGrey10};
    padding: 12px 8px;
    font-size: 18px;
  }

  .simple-input:focus {
    outline: none !important;
    border:none !important;
    background: ${colors.PrimaryBlue10};
  }



  .react-tel-input {
    font-family: inherit;
  }

  .special-label {
    display: block;
    padding-left: 0;
    width: 100%;
    display: block;
    color: #353e59;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
    background: transparent;
    left: 0;
  }

  input::-webkit-input-placeholder,
  input:-ms-input-placeholder,
  input::placeholder {
    color: ${colors.SecondaryGrey40};
    font-size: 18px;
  }

  .input-holder {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .input-error {
    .simple-input {
      border: 1px solid ${colors.PrimaryMagenta100};
    }
  }

  .error_feedback {
    color: ${colors.PrimaryMagenta100};
    font-size: 12px;
    padding: 8px 0px;
  }
`;
