import React, { useEffect, useState } from "react";
import { utils } from "infrastructure";
import { useQuery } from "@apollo/client";

const ILIKE_FIELDS = ["name", "email"];
const HAS_KEY_FIELDS = [];

const COMPANY_USERS_BASE_CONFIG = {
  entity: "company_users_view",
  fields: ["id", "name", "email", "profile_img", "role_human_id"],
  searchTextConfig: { ilikeFields: ILIKE_FIELDS, hasKeyFields: [] },
  text: false,
  filters: false,
  limit: 10,
  offset: 0,
  withAggregation: true,
};

const baseConfig = COMPANY_USERS_BASE_CONFIG;

const useQueryCompanyUsers = ({
  searchText = "",
  filters = [],
  pagination = {},
  fields = COMPANY_USERS_BASE_CONFIG.fields,
} = {}) => {
  const {
    loading,
    error,
    data,
  } = utils.queries.hooks.useQueryWithTextSearchAndFilters(useQuery)({
    ...COMPANY_USERS_BASE_CONFIG,
    ...pagination,
    fields,
    text: searchText,
    filters,
  });

  return { loading, error, data };
};

const useSearchText = () => {
  const [searchText, setSearchText] = useState("");
  return { searchText, setSearchText };
};

//this Filters need to be refactored to useFilters, extract generic types as constants to do ir more reusable
// also the section of filter should have something to enable or disable all the filters
// pass the filterItems as config and make this useFilters something generic as the component(s)
const useCompanyUsersFilters = () => {
  const [filterValues, setFilterValues] = useState([]);

  const updateFilters = ({ field, condition, value }) => {
    const filtersCleaned = filterValues.filter(
      ({ field: innerField }) => innerField !== field
    );
    setFilterValues([...filtersCleaned, { field, condition, value }]);
  };

  const filterItems = [];

  return { filterItems, filterValues };
};

const useCompanyUsers = () => {
  const [numItems, setNumItems] = useState(0);

  const { filterItems, filterValues: filters } = useCompanyUsersFilters();
  const { searchText, setSearchText } = useSearchText();

  const {
    paginationValues,
    ...pagination
  } = utils.queries.hooks.usePagination({ numItems, baseConfig });

  const { loading, error, data } = useQueryCompanyUsers({
    searchText,
    filters,
    pagination: paginationValues,
  });

  useEffect(() => {
    if (!loading && data) {
      setNumItems(
        utils.queries.utils.countFromAggregation({ data, baseConfig })
      );
    }
  }, [data, loading]);

  return { loading, data, error, filterItems, setSearchText, pagination };
};

export default useCompanyUsers;
