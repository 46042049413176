import {
  isStringAndNotNull,
  isEmailValid,
  isPasswordValid,
  errorsByCode,
  isPhoneNumberValid,
} from "utils";

const VALIDATION_CONFIG = {
  username: {
    validations: [isStringAndNotNull, isEmailValid],
    errorKey: errorsByCode.EmailNotValidException,
  },
  password: {
    validations: [isStringAndNotNull, isPasswordValid],
    errorKey: errorsByCode.PasswordlNotValidException,
  },
  username: {
    validations: [isStringAndNotNull, isEmailValid],
    errorKey: errorsByCode.EmailNotValidException,
  },
  terms: {
    validations: [(terms) => terms != false],
    errorKey: errorsByCode.TermsNotValidException,
  },
  telephone: {
    validations: [isPhoneNumberValid],
    errorKey: errorsByCode.InvalidPhoneNumber,
  },
  companyName: {
    validations: [isStringAndNotNull],
    errorKey: errorsByCode.InvalidField,
  },
  administrativeCode: {
    validations: [isStringAndNotNull],
    errorKey: errorsByCode.InvalidField,
  },
  contactName: {
    validations: [isStringAndNotNull],
    errorKey: errorsByCode.InvalidField,
  },
  billingEmail: {
    validations: [isStringAndNotNull, isEmailValid],
    errorKey: errorsByCode.EmailNotValidException,
  },
};

export default VALIDATION_CONFIG;
