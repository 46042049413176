import styled from "styled-components";
import InvitationsList from "./component";

export default styled(InvitationsList)`
  padding: 16px;
  color: #353e59;
  max-width: min(100vw, 720px);
  margin: 32px auto;

  .header-with-search{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .simple-input{
        padding: 8px;
    }
  }

  .row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .row_head_item{
        flex: 1
        display: flex;
        
        align-items: center;
        padding: 8px;
        border: 1px solid #2c306e;
        font-weight: bold;


    }

    .item{
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    display:flex;
    
    
        
        padding: 8px;
        border: 1px solid #2c306e;

        &.item-lg{
            flex: 3;
        }
        
        span{
            box-sizing: inherit;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
        
        }
    
    }
  }
`;
