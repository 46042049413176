import { useState, useEffect } from 'react'
import { Auth } from "aws-amplify";

const useGetUserId = () => {
    const [userId, setUserId] = useState('')

    const getUserId = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser()
            const { sub } = user.attributes
            setUserId(sub)
            return sub
        } catch (e) {
            console.log(e)
            return false
        }
    }

    useEffect(() => {
        getUserId()
    }, [])
    return { userId, getUserId }
}

export default useGetUserId;