import React from "react";
import { Input, Button, Checkbox } from "components/FormComponents";
import CompanyInfoForm from "components/CompanyInfoForm";
import CallMe from "components/CallMe";

const MyCompany = ({ className: styledClassName }) => {
  return (
    <div className={styledClassName}>
      <div className="company-form">
        <CompanyInfoForm />
      </div>
      <CallMe />
    </div>
  );
};

export default MyCompany;
