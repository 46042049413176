import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import analytics from "../../../analytics";

import { Auth } from "aws-amplify";
import { removeErrorFromErrors, getErrorByCode, ValidateForm } from "utils";
import VALIDATION_CONFIG from "./VALIDATION_CONFIG";

const useSignIn = () => {
  const history = useHistory();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    analytics.methods.track(analytics.events.auth.loginSubmitted());
    // console.log("LoginSubmitted event tracked")
    setLoading(true);
    setErrors({});
    try {
      const { isValid, errors } = ValidateForm({
        ...VALIDATION_CONFIG,
      })({ username, password });

      if (isValid) {
        const user = await Auth.signIn(username, password);
        console.log(user);

        await analytics.methods.identifyByUser({ user });
        analytics.methods.track(
          analytics.events.auth.loginSucceed({
            userId: user.attributes.sub,
          })
        );
        // console.log("LoginSucceedSucceed event sent")

        /*if (user) {
          window.location.href = "/";
        }*/
      } else {
        setErrors(errors);
      }
    } catch (e) {
      analytics.methods.track(analytics.events.auth.loginError());
      // console.log("LoginError event sent")
      console.log("error", e);
      const errorCode = { request: getErrorByCode(e.code) };
      setErrors(errorCode);
    } finally {
      setLoading(false);
    }
  };

  const handlerByName = {
    username: setUserName,
    password: setPassword,
  };
  const handleChange = ({ name, value }) => {
    const handler = handlerByName[name];
    if (handler) handler(value);

    const newErrors = removeErrorFromErrors({
      errors: errors,
      errorKey: name,
    });

    setErrors(newErrors);
  };
  return {
    values: { username, password },
    errors,
    loading,
    handleSubmit,
    handleChange,
  };
};
export default useSignIn;
