import React from "react";

const Header = ({ className: styledClassName }) => {
  return (
    <div className={`${styledClassName}`}>
      <div className="brand-wrapper">
        <span className="logo">
          <img
            src="/assets/images/wannalisn-negative@3x.png"
            width="24"
            height="auto"
            alt="Wannalisn logo"
          />
        </span>
        <h2 className="brand-title">wannalisn</h2>
      </div>
    </div>
  );
};

export default Header;
