import styled from "styled-components"
import SideBarLayout from "./component"
import { colors } from "../../../utils"

export default styled(SideBarLayout)`
    display: grid;
    
    &,
    .sidebar-container {
        position: relative;
    }

    .sidebar-container {
        .toggle-button {
            transition: 300ms all ease-in-out;
            height: 48px;
            width: 16px;
            padding: 0px;
            background: ${colors.SecondaryGrey40};
            position: fixed;
            top: 50%;
            left: 15%;
            box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.25);

            svg {
                width: 100%;
                height: 100%;
            }

            &,
            :active,
            :focus {
                outline: none;
                border: none;
            }
        }
    }

    &.sidebar-expanded {
        grid-template-columns: 15% auto;
        .sidebar {
            &,
            .sidebar-list {
                transition: 300ms all ease-in-out;
                width: 15%;
            }
        }
    }

    &.sidebar-collapsed {
        grid-template-columns: 40px auto;
        .sidebar {
            &,
            .sidebar-list {
                transition: 300ms all ease-in-out;
                width: 40px;
            }
            .item-link-container .link-wrapper {
                display: none;
            }
        }

        .sidebar-container {
            .toggle-button {
                left: 40px;
            }
        }

        .brand-wrapper .brand-title {
            display: none;
        }
    }

    .sidebar-content {
        padding:32px;
    }
`
