import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const query = gql`
  {
    companies {
      id
      company_name
      administrative_code
      billing_email
      stripe_customer_id
    }
  }
`;

const useGetUserCompany = () => {
  const [company, setCompany] = useState(false);
  const {
    data,
    loading,
    errors,
    refetch: refreshCompanyQuery,
  } = useQuery(query, { fetchPolicy: "no-cache" });
  useEffect(() => {
    if (!loading && data && data.companies.length > 0) {
      setCompany(data.companies[0]);
    }
  }, [loading, data]);
  return { company, refreshCompanyQuery, loading, errors };
};

export default useGetUserCompany;
