import { useEffect } from "react";

const useOnKeyPressListener = ({
  expectedKeys = [],
  action = null,
  isEnabled = true,
}) => {
  const handleKeyPress = (event) => {
    if (isEnabled && expectedKeys.includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
      action?.();
    }
  };

  return useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);

    return () => document.removeEventListener("keypress", handleKeyPress);
  }, [action, isEnabled]);
};

export default useOnKeyPressListener;
