import * as React from "react";

function SvgClose(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        transform="rotate(-45 14.828 5.172)"
        fill={props.currentColor || "#FFF"}
        fillRule="evenodd"
      >
        <rect width={16} height={2} y={7} rx={1} />
        <path d="M9 1v14a1 1 0 01-2 0V1a1 1 0 012 0z" opacity={0.5} />
      </g>
    </svg>
  );
}

export default SvgClose;
