import Analytics from "analytics-node";
import { Auth } from "aws-amplify";

const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY || null;
const client = new Analytics(SEGMENT_WRITE_KEY);

const getUserId = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.sub;
  } catch (e) {
    return null;
  }
};

const getSegmentWebClient = () => {
  // windows is available and defined globally from the snipped provided by Segment.io in public/index.html
  // if you are seeing compilation errors, you may need to declare the windows interface as a global
  if (!window.analytics) {
    // eslint-disable-next-line no-console
    console.warn("No Segment Snippet Loaded");
    return false;
  }
  return window.analytics;
};

const getSegmentAnonymousId = () => {
  const client = getSegmentWebClient();
  return client.user().anonymousId();
};

const identify = async (traits = {}) => {
  try {
    const userId = await getUserId();
    client.identify({
      userId,
      anonymousId: getSegmentAnonymousId(),
      traits,
    });
  } catch (err) {
    console.log(err);
  }
};

// Legacy method - please, use 'identify'
const identifyByUser = async ({ user, properties, email, sub }) => {
  try {
    if (user && user.attributes) {
      const traits = {
        email: user.attributes.email,
        username: user.attributes["custom:username"],
        terms: Boolean(user.attributes["custom:terms"]),
        communications: Boolean(user.attributes["custom:communications"]),
        notifications: false,
      };
      await identify(traits);
    }
  } catch (err) {
    console.log(err);
  }
};

const track = ({ type, properties, userId, anonymousId }) => {
  try {
    client.track({
      userId: userId || "anonymous@anonymous",
      anonymousId: anonymousId || getSegmentAnonymousId(),
      event: type,
      context: {
        app: {
          name: navigator.appName,
          version: navigator.appVersion,
        },
        locale: navigator.language,
        screen: {
          height: window.screen.availHeight,
          width: window.screen.availWidth,
        },
        userAgent: navigator.userAgent,
      },
      properties: {
        userAgent: navigator.userAgent,
        ...properties,
      },
    });
  } catch (segmentError) {
    // eslint-disable-next-line no-console
    console.error("ERROR sending event to segment", segmentError);
  }
};

const methods = {
  identifyByUser,
  getSegmentWebClient,
  identify,
  track,
};
export default methods;
