import styled from "styled-components";
import OptionsMenu from "./component";
import { colors } from "utils";

export default styled(OptionsMenu)`
  position: relative;
  padding-bottom: 4px;

  .option-menu-wrapper {
    position: absolute;
    padding: 8px;
    background: ${colors.white};
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    right: 0px;
    top: 100%;
    width: 100%;

    .option-menu-list {
      padding: 0px;
      margin: 0px;
    }

    .option-menu-list-item {
      list-style: none;
      padding: 0px;
      cursor: pointer;
      text-align: left;
      span {
        font-size: 12px;
      }
    }
  }

  .action-button {
    .label {
      font-size: 16px;
      font-weight: 300;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: right;
      display: inline-flex;
      align-items: center;

      span {
        margin-right: 8px;
      }
    }
  }
`;
