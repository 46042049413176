import { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

const query = gql`
  {
    company_subscriptions {
      id
      subscription_id
      active
    }
  }
`;

const useHasPremiumPlan = () => {
  const [hasPlan, setHasPlan] = useState(false);
  const { data, loading, errors } = useQuery(query, {
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    if (!loading && data && data.company_subscriptions.length > 0) {
      setHasPlan(data.company_subscriptions[0].active);
    }
  }, [loading, data]);
  return { hasPlan, loading, errors };
};

export default useHasPremiumPlan;
