import React from 'react'

export const BaseCheckbox = ({
  label,
  styles,
  status,
  isLoading,
  disabled,
  className: styledClassName,
  onChange,
  name,
  handleModalState,
  ...props
}) => (
    <div>
      <input
        disabled={disabled || isLoading}
        type="checkbox" {...props}
        className={`${styledClassName} base-checkbox`}
        accessibilitylabel={label}
        accessibilityrole="checkbox"
        onChange={(e) => onChange && onChange({ name, value: e.target.checked }, e)}
        {...props}
      />
      {handleModalState?
        <a href="#" onClick={() => handleModalState(true)}>{label}</a>
        :
        <label>{label}</label>
      }
    </div>

  )

const Checkbox = ({
  type,
  isLoading,
  disabled,
  className: styledClassName,
  elevation = 0,
  ...props
}) => {

  return (
    <BaseCheckbox
      isLoading={isLoading}
      disabled={disabled}
      className={`${styledClassName} wann-checkbox ${type} elevation-${elevation}`}
      {...props}
    />
  )
}

export default Checkbox
