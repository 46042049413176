import styled from "styled-components";
import Billing from "./component";
import { colors } from "utils";

export default styled(Billing)`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: min(560px, 100vw);
  color: ${colors.PrimaryBlue150};
  background: ${colors.Background};

  h1 {
    padding: 16px 20px 2px;
  }

  p {
    padding: 2px 20px;
  }

  .prices-container {
    display: flex;

    margin: 16px 0px;
    padding: 0 16px;

    background: ${colors.Background};

    .price__container {
      background: white;

      &:first-of-type {
        margin-right: 8px;
      }

      &:last-of-type {
        margin-left: 8px;
      }

      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      border-radius: 4px;
      padding: 12px 18px;

      &.active {
        color: ${colors.PrimaryBlue100};
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
        border: 1px solid ${colors.PrimaryBlue100};
      }
      &.innactive {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
        color: ${colors.PrimaryBlue30};
      }

      &:hover {
        cursor: pointer;
        transition: transform 0.02s;
        transform: scale(1.03);
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.14) !important;
        color: ${colors.PrimaryBlue100};
        border: 1px solid ${colors.PrimaryBlue100};
      }

      &:active {
        transform: scale(1);
      }

      .price__title {
      }

      .price__offer {
        margin: auto;

        padding: 4px 8px;
        border-radius: 4px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-10px, -50%);
        background: ${colors.PrimaryBlue20};
        font-weight: bold;
      }

      .price_amount_container {
        display: flex;

        flex-direction: column;
        justify-content: center;

        .price_amount {
          display: flex;
          justify-content: initial;

          align-items: flex-end;
          flex-direction: row;

          .price_amount_quantity_container {
            .price_amount_quantity {
              justify-content: end;
              font-size: 6ch;
              font-family: "Muli-bold";
            }
          }

          .price_amount_info_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .price_amount_currency {
              font-size: 2ch;
              font-family: "Muli-bold";
            }

            .price_amount_frequency {
              font-size: 1.3ch;
            }
          }
        }

        .price_amount_info {
          font-size: 14px;
          color: ${colors.PrimaryBlue30};
        }
        .price_amount_info_bold {
          font-size: 14px;
          font-weight: bold;
          color: ${colors.PrimaryBlue30};
        }
      }
    }
  }

  .StripeElement {
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    background: ${colors.SecondaryGrey10};
    padding: 12px 8px;
    font-size: 18px;
    margin: 4px auto;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .payment_form {
    
    
    
    position: relative;
    background: ${colors.white};
    padding: 18px 18px;
    
    position: relative;
    
    

    h3 {
      margin-bottom: 16px;
    }

    label {
      color: ${colors.PrimaryBlue150};
    }

    .error {
      color: ${colors.yellowOrange};
      padding: 0;
    }
    .payment_form_second_block {
      display: flex;
      margin-top: 8px;

      .payment_form_second_exp {
        flex: 2;
        margin-right: 8px;
      }

      .payment_form_second_cvc {
        flex: 1;
      }
      .payment_form_second_spacer {
        flex: 3;
      }
    }

    .stripe_logo {
      width: 12ch;
      position: absolute;
      top: 16px;
      right: 18px;
      background: white;
      opacity: 0.3;
    }
  }

  .summary_container {
    margin: 16px;
    width: 50%;
    margin-left: 50%;
    margin-right: 16px;
    padding: 16px;
    text-align: right;
    margin-bottom: 0px;
    padding-bottom: 0px;
    opacity: 0.6;

    p {
      padding: 0;
    }

    hr {
      border-color: ${colors.PrimaryBlue150};
    }

    .concept {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .separator {
      border-top: 1px solid #42457d;
      margin: 8px 0px;
    }

    .total {
      font-weight: bold;
    }
  }

  .button_container {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px;

    .submit {
      flex: 2;
    }
  }

  .cancel {
    background: transparent;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 20px;
  }

  .easter{
    border: none;
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    background: white;
    width: 16px;
    cursor: pointer;
  }
`;
