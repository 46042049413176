import React, { useState, useEffect } from "react"
import { Input } from "../index"
import { KEY_CODE } from "utils"

const TagsInput = ({
    className: styledClassName,
    initialvalue,
    onChange,
    name,
}) => {
    const [tags, setTags] = useState([])
    const [isInputActive, setIsInputActive] = useState(false)
    const [inputVal, setInputVal] = useState("")

    useEffect(() => {
        setTags(initialvalue)
    }, [initialvalue])

    const addTags = (event) => {
        const value = event.target.value.trim().split(" ").join("")
        setInputVal(value)
        setIsInputActive(event.key !== KEY_CODE.ENTER)
        if (
            (event.key === KEY_CODE.SPACE ||
            event.key === KEY_CODE.ENTER) && value !== ""
        ) {
            const newTags = [...(tags || []), event.target.value]
            setTags(newTags)
            onChange({ name, value: newTags })
            setInputVal("")
        }

        if (event.key === KEY_CODE.BACKSPACE && value === "") {
            const tagsLastIndex = tags.length - 1
            removeTags(tagsLastIndex)
        }
    }
    const removeTags = (index) => {
        const newTags = [
            ...(tags || []).filter(
                (tag) => (tags || []).indexOf(tag) !== index
            ),
        ]
        setTags(newTags)
        onChange({ name, value: newTags })
    }

    return (
        <div className={`${styledClassName} tags-input`}>
            <ul className="tags">
                {(tags || []).map((tag, index) => (
                    <li className="tag" key={index}>
                        <span className="tag-title">{tag}</span>
                        <span
                            className="tag-close-icon"
                            onClick={() => removeTags(index)}
                        >
                            <img alt="close" src="../assets/images/close.svg" />
                        </span>
                    </li>
                ))}
                <li>
                    {isInputActive ? (
                        <Input
                            onKeyUp={(event) => addTags(event)}
                            onChange={(_, event) => addTags(event)}
                            placeholder="Press enter to add tags"
                            className="input-tags"
                            value={inputVal}
                        />
                    ) : (
                        <button
                            className="plus-button"
                            onClick={() => setIsInputActive(true)}
                        >
                            <img alt="plus" src="../assets/images/plus.svg" />
                        </button>
                    )}
                </li>
            </ul>
        </div>
    )
}

export default TagsInput
