import styled from "styled-components";
import CompanyUserDetail from "./component";
import { colors } from "utils";

export default styled(CompanyUserDetail)`
  display: grid;
  grid-template-rows: 1fr 10fr 1fr;
  border-right: solid 2px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  padding: 16px;

  .company-user-header {
    padding: 16px 16px 32px;
    border-radius: 4px;
    background: ${colors.white};
  }

  .company-user-header-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;

    .company-user-profile-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .company-user-profile-image-wrapper {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      overflow: hidden;
      background: ${colors.SecondaryGrey30};
      .company-user-profile-image {
        max-width: 100%;
        height: auto;
      }
    }

    .company-user-profile-info-container {
      padding-left: 16px;
      text-align: left;
      color: ${colors.PrimaryBlack};

      .company-user-profile-info-name {
        font-family: Muli;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .company-user-profile-info-email {
        font-size: 16px;
      }
    }

    .company-user-actions-container {
    }
  }

  .company-user-main {
    width: 100%;
    padding-top: 16px;
    box-sizing: border-box;
  }

  .company-user-footer {
    width: 100%;
    background: ${colors.white};
  }
`;
