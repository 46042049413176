import Auth from "i18n/es/Auth";
import Date from "i18n/es/Date";
import Errors from "i18n/es/Errors";
import Navigation from "i18n/es/Navigation";
import Users from "i18n/es/Users";
import Pagination from "i18n/es/Pagination";
import Invites from "i18n/es/Invites";
import InvitationsPage from "i18n/es/InvitationsPage";
import Billing from "i18n/es/Billing";

const lang = {
  Auth,
  Date,
  Errors,
  Invites,
  InvitationsPage,
  Navigation,
  Users,
  Billing,
  Pagination,
  Users,
};

export default lang;
