/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "eu-central-1",
  aws_cognito_identity_pool_id:
    "eu-central-1:ca0a863d-bb6f-4a0a-a5ac-14d866d44906",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_x39qDxMhx",
  aws_user_pools_web_client_id: "2m19074d1sl5p8s6kdg7f2tusd",
  authenticationFlowType: "USER_PASSWORD_AUTH",
  federationTarget: "COGNITO_USER_POOLS",
  oauth: {},
};

export default awsmobile;
