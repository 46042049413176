import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const GoBack = ({ className: styledClassName, children, ...props }) => {
  return (
    <div className={`${styledClassName}`}>
      <Link to="users">
        <Trans i18nKey="Auth:go_back">Go back</Trans>
      </Link>
    </div>
  );
};

export default GoBack;
