import React, { useEffect } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import CalendarHeatmap from "react-calendar-heatmap";
import ReactTooltip from "react-tooltip";
import "react-calendar-heatmap/dist/styles.css";

const GET_TASK_DONE_COMPANY_USER_BY_ID = gql`
  query getTasksDoneByCompanyUser($company_user_id: uuid!) {
    company_with_users_and_roles(where: { id: { _eq: $company_user_id } }) {
      tasks_done_by_company_users_per_day {
        aws_id
        date
        count
      }
    }
  }
`;

const MAX_VALUE = 3;

const MONTHS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sept",
  "oct",
  "nov",
  "dec",
];

const UserTracking = ({ className: styledClassName = "", userId = null }) => {
  const { t } = useTranslation();
  const [
    getTasksDoneByCompanyUser,
    { loading, error, data: { company_with_users_and_roles = null } = {} },
  ] = useLazyQuery(GET_TASK_DONE_COMPANY_USER_BY_ID, {
    variables: {
      company_user_id: userId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (userId) getTasksDoneByCompanyUser?.();
  }, [userId]);

  const tasksDoneByCompanyUser = (
    company_with_users_and_roles?.[0]?.tasks_done_by_company_users_per_day || []
  ).map((task) => ({
    count: task.count,
    level: task.count > 10 ? 3 : task.count > 5 ? 2 : task.count > 0 ? 1 : 0,
    date: new Date(task.date),
  }));

  const monthsTranslated = MONTHS.map((month) => t(`Date:${month}`));

  return (
    <div className={styledClassName}>
      <div className="title-container">
        <h2 className="title">{t("Users:user_tracking")}</h2>
      </div>
      {tasksDoneByCompanyUser && (
        <CalendarHeatmap
          startDate={
            new Date(new Date().setFullYear(new Date().getFullYear() - 1))
          }
          endDate={new Date()}
          monthLabels={monthsTranslated}
          values={tasksDoneByCompanyUser}
          classForValue={(value) => {
            if (!value) {
              return "color-empty";
            }
            return `color-scale-${value.level}`;
          }}
          tooltipDataAttrs={(value) => {
            const { date, count } = value;
            return {
              "data-tip":
                date && count
                  ? t("Users:tooltip_user_tracking", {
                      date: `${new Date(date).toLocaleDateString()}`,
                      tasks: count || 0,
                    })
                  : "",
            };
          }}
        />
      )}
      <ReactTooltip />
    </div>
  );
};

export default UserTracking;
