import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import analytics from "../../../analytics";
import { getErrorByCode, ValidateForm } from "utils";
import VALIDATION_CONFIG from "./VALIDATION_CONFIG";

const useForgotPassword = () => {
  // form data
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [code, setCode] = useState();
  // state
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    analytics.methods.track(analytics.events.auth.forgotView());
    // console.log("ForgotView event sent")
  }, []);

  const validate = () => {
    return {
      isValid: true,
      errors: {},
    };
  };

  const handleSubmitForgotPassword = async () => {
    setLoading(true);
    setErrors({});
    try {
      const { isValid, errors } = ValidateForm({
        ...VALIDATION_CONFIG,
      })({ username });

      if (isValid) {
        const data = await Auth.forgotPassword(username);

        setWaitingForCode(true);
      } else {
        setErrors(errors);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitCode = async ({ handleCreateCompany }) => {
    setLoading(true);
    setErrors({});
    try {
      const { isValid, errors } = ValidateForm({
        ...VALIDATION_CONFIG,
      })({
        code,
        password,
        username,
      });
      console.log({ isValid, errors });
      if (isValid) {
        const data = await Auth.forgotPasswordSubmit(username, code, password);

        await Auth.signIn(username, password);
        if (handleCreateCompany) {
          await handleCreateCompany();
        }
      } else {
        setErrors(errors);
      }
    } catch (e) {
      console.log("error", e);
      const errorCode = { request: getErrorByCode(e.code) };
      setErrors(errorCode);
    } finally {
      setLoading(false);
    }
  };

  const handlerByName = {
    username: setUserName,
    password: setPassword,
    code: setCode,
  };
  const handleChange = ({ name, value }) => {
    const handler = handlerByName[name];
    if (handler) handler(value);
  };
  return {
    values: { username, password, code },
    errors,
    loading,
    waitingForCode,
    setWaitingForCode,
    handleSubmit: waitingForCode
      ? handleSubmitCode
      : handleSubmitForgotPassword,
    handleChange,
  };
};
export default useForgotPassword;
