import styled from "styled-components";
import MyCompany from "./page";
// import colors from "utils/colors"

export default styled(MyCompany)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  .company-form {
    padding: 20px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;

    .input-holder {
      margin-top: 24px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .base-button {
      margin-top: 24px;
    }
  }
`;
