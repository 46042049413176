import React from "react";
import { useTranslation } from "react-i18next";
import { Input } from "components/FormComponents";
import Button from "components/Button";
import domain from "appDomain";

const MailInput = ({
  user,
  updateUserWithKey,
  removeFromUserListByKey,
  className: styledClassName,
  index,
}) => {
  const { t } = useTranslation();
  const { key, mail, state, error = false } = user;

  return (
    <div className={styledClassName} key={key}>
      <span>{index}. </span>
      <Input
        disabled={
          state === domain.invites.states.SENT ||
          state === domain.invites.states.USER_EXISTS
        }
        onChange={({ value }) => updateUserWithKey(key, value)}
        value={mail}
        error={error && t("Invites:" + error)}
        success={t(domain.invites.successMesssages[state])}
        placeholder="name@mail.com"
      />
      <Button
        label="X"
        onClick={() => removeFromUserListByKey(key)}
        flat={true}
      />
    </div>
  );
};

export default MailInput;
