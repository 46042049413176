import React, { useState, useEffect } from "react";

const ToastContainer = ({
  className: styledClassName = "",
  children
}) => {

  return (
    <div className={`${styledClassName}`} id="toast-container">
      {children}
    </div>
  );
};

export default ToastContainer;
