import React from "react";
import {
  Input,
  InputCheck,
  InputSelect,
  InputTags,
} from "components/FormComponents";

const handler = ({ name, value, listener, condition }) =>
  listener && listener({ field: name, condition, value });

const getListenersFormated = ({ listeners = {}, condition }) =>
  Object.keys(listeners).reduce(
    (acc, listenerKey) => ({
      ...acc,
      [listenerKey]: ({ name, value }) =>
        handler({ name, value, listener: listeners[listenerKey], condition }),
    }),
    {}
  );

const FilterItem = ({
  className: styledClassName = "",
  type,
  label,
  value,
  name,
  listeners = null,
  options = [],
  condition = null,
  ...props
}) => {
  const listenersFormated = getListenersFormated({ listeners, condition });

  const FilterComponentSelector = {
    input: (
      <Input
        name={name}
        label={label}
        initialvalue={value}
        condition={condition}
        {...(listenersFormated || {})}
        {...props}
      />
    ),
    checkbox: (
      <InputCheck
        name={name}
        label={label}
        {...(listenersFormated || {})}
        {...props}
      />
    ),
    select: (
      <InputSelect
        name={name}
        label={label}
        options={options}
        condition={condition}
        {...(listenersFormated || {})}
        {...props}
      />
    ),
    tags: (
      <InputTags
        initialvalue={value}
        label={label}
        condition={condition}
        {...(listenersFormated || {})}
        {...props}
      />
    ),
  };

  return (
    <div className={`${styledClassName} filter-item`}>
      {FilterComponentSelector?.[type] || null}
    </div>
  );
};

export default FilterItem;
