import React from "react";

const InputSelect = ({ className: styledClassName, value, onChange, name, label, options, isControlled = false }) => {
  

    return(
        <div className={ `${styledClassName} input-holder`}>
        {label && <span className="label"> {label}:</span>}
        <select
          id={name}
          initialvalue={value}
          onChange={e => {
            onChange({ name, value: e.target.value })
          }}
          className="select"
          name={name}
          {...(isControlled ? {value} : {})}
        >
            {options.map(option => 
                <option key={option.value} value={option.value}>{option.label}</option>
                )}
        </select>
        
      </div>
    )
}

export default InputSelect