import React, { useEffect } from "react";
import OptionsMenu from "components/OptionsMenu";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import domain from "appDomain";
import UserTracking from "../UserTracking";
import CompanyUserNotFound from "../CompanyUserNotFound";
import Loader from "components/Loader";

const CompanyUserDetail = ({ className: styledClassName = "" }) => {
  const { t } = useTranslation();

  const OptionsMenuList = [
    {
      name: t("Users:disabled_user"),
      action: () => console.log("Disable user"),
    },
  ];
  const { id: userId = "" } = useParams();
  const {
    getCompanyUser,
    loading,
    error,
    user,
  } = domain.users.hooks.useGetCompanyUserById({ userId });

  useEffect(() => {
    if (userId) getCompanyUser?.();
  }, [userId]);

  const { name = "...", email = "...", profile_img = "" } = user || {};

  return (
    <section className={`${styledClassName} company-user-section`}>
      <header className="company-user-header">
        <div className="company-user-header-wrapper">
          <div className="company-user-profile-container">
            <div className="company-user-profile-image-wrapper">
              <img className="company-user-profile-image" src={profile_img} />
            </div>

            <div className="company-user-profile-info-container">
              <h1 className="company-user-profile-info-name">{name}</h1>
              <p className="company-user-profile-info-email">{email}</p>
            </div>
          </div>

          <div className="company-user-actions-container">
            <OptionsMenu
              optionList={OptionsMenuList}
              label={t("Users:more_options")}
            />
          </div>
        </div>
      </header>

      <div className="company-user-main">
        {loading && <Loader />}
        {!loading && !user && <CompanyUserNotFound />}
        {user && <UserTracking userId={userId} />}
      </div>
      <footer className="company-user-footer"></footer>
    </section>
  );
};
export default CompanyUserDetail;
