import * as React from "react";

function SvgVideos(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M3.5 19h17a1.5 1.5 0 001.5-1.5v-11A1.5 1.5 0 0020.5 5H10L7.44 2.44A1.5 1.5 0 006.378 2H3.5A1.5 1.5 0 002 3.5v14A1.5 1.5 0 003.5 19z"
          opacity={0.3}
        />
        <path
          fill={props.currentColor || "#FFF"}
          fillRule="nonzero"
          d="M10.782 15.51l4.404-3.01a.5.5 0 000-.825l-4.403-3.029a.5.5 0 00-.783.412v6.04a.5.5 0 00.782.412z"
        />
      </g>
    </svg>
  );
}

export default SvgVideos;
