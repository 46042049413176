import styled from "styled-components"
import SideBar from "./component"
import { colors } from "../../../utils"

export default styled(SideBar)`
    display: grid;
    grid-template-rows: 102px auto auto;
    grid-template-columns:100%;
    border-radius: 0px 6px 6px 0px;
    position: fixed;
    width: 15%;
    height: 100vh;
    top: 0px;
    background: ${colors.PrimaryBlue150};
    padding-bottom: 41px;

    .sidebar-list {
        list-style: none;
        padding: 0px;
        li a {
            text-decoration: none;
            cursor: pointer;
            color: ${colors.white};
        }
    }

    .nav-footer {
        margin-top: auto;
        margin-bottom: 42px;

        a {
            color: ${colors.white};
            text-decoration: none;
            cursor: pointer;
        }
    }
`
