import React, { useState, useEffect, useRef } from "react";
import CompanyUserCard from "../CompanyUserCard";
import { useHistory } from "react-router-dom";
import CompanyUserNotFound from "../CompanyUserNotFound";
import Loader from "components/Loader";

const CompanyUserList = ({
  className: styledClassName = "",
  loading,
  data,
  error,
}) => {
  const [activeUserCard, setActiveUserCard] = useState(null);
  const history = useHistory();

  const handleCompanyUserClick = (companyUser, event) => {
    setActiveUserCard(companyUser.id);
    history.push(`/users/${companyUser.id}`, companyUser);
  };

  if (loading) return <Loader />;
  if (error) return <CompanyUserNotFound />;

  return (
    <div className={`${styledClassName} company-user-list-container`}>
      {(data?.company_users_view || []).map((companyUser, key) => (
        <CompanyUserCard
          isActive={activeUserCard === companyUser.id}
          companyUser={companyUser}
          key={key}
          index={key}
          onClick={handleCompanyUserClick}
        />
      ))}
      {data?.company_users_view?.length === 0 && <CompanyUserNotFound />}
    </div>
  );
};

export default CompanyUserList;
