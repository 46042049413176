import React from "react"
import TagsInput from "components/FormComponents/TagsInput"

const InputTags = ({ initialvalue, onChange, name, label }) => {
    return (
        <div className="input-holder">
            <span className="label"> {label}:</span>
            <TagsInput
                initialvalue={initialvalue}
                onChange={onChange}
                name={name}
            />
        </div>
    )
}

export default InputTags
