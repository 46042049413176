import styled from "styled-components"
import TagsInput from "./component"
import { colors } from "../../../utils"

// TODO: should background color come from backend or be set in a ENUM in the front ???

export default styled(TagsInput)`
    .tags {
        display: flex;
        align-items:center;
        justify-content: flex-start;
        align-content: center;
        flex-wrap:wrap;
        list-style: none;
        background: ${colors.white};
        border: 1px solid ${colors.SecondaryGrey30};
        border-radius: 6px;
        margin:0px;
        padding:0px 12px;
        min-height:45px;

        .tag {
            height: 24px;
            border-radius: 4px;
            padding: 0px 8px;
            background: ${colors.PrimaryBlue100};
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;

            .tag-close-icon {
                padding-left: 4px;
                color: ${colors.PrimaryMagenta100};
                font-weight: bold;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            .tag-title {
                color: ${colors.white};
                font-size: 12px;
            }
        }
    }

    .plus-button {
        background: transparent;
        border:none;
        display:flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        cursor: pointer;

        :active, :focus {
            border: none;
            outline: none;
        }
    }

    .input-tags {
        width: 100%;
        .simple-input {
            background: ${colors.white};
            border: none;
            border-radius: 0px;
        }
    }
`
