import { useTranslation } from "react-i18next";

const useImportUsersDropzoneStatus = ({ progress, errors, userList }) => {
  const { t } = useTranslation();

  const hasUsersNotInvited =
    userList.filter((user) => user.hasOwnProperty("error")).length > 0;

  if (errors.length > 0) {
    let errorMsg = "";
    errors.forEach((error) => {
      errorMsg += "\n" + t(error);
    });
    return {
      dropMessage: errorMsg,
      dropIcon: "error",
    };
  }

  if (!progress) {
    return {
      dropMessage: t("Invites:drop_file_here"),
      buttonMessage: t("Invites:click_here_to_find_file"),
      dropIcon: "default",
    };
  }

  if (progress < 0) {
    return {
      dropMessage: t("Invites:submit_invite_mates"),
      dropIcon: "okey",
    };
  }

  if (progress > 0 && progress < 1) {
    return {
      dropMessage: t("Invites:sending_invitations"),
      dropIcon: "okey",
    };
  }

  if (progress === 1) {
    return hasUsersNotInvited
      ? {
          dropMessage: t("Invites:error_in_at_least_one_invite"),
          dropIcon: "error",
        }
      : { dropMessage: t("Invites:success_inviting"), dropIcon: "okey" };
  }

  return {
    dropMessage: t("Invites:inviting_progress", { progress }),
    dropIcon: "loading",
  };
};
export default useImportUsersDropzoneStatus;
