import React, { useState } from "react";
import { Input } from "components/FormComponents";
import Button from "components/Button";
import { Eye, EyeClose } from "components/Icons";

const PasswordInput = ({ className: styledClassName = "", ...props }) => {
  const [showPassword, setShowPassowrd] = useState(false);
  const Icon = showPassword ? Eye : EyeClose;

  return (
    <div className={`${styledClassName}`}>
      <Input {...props} type={showPassword ? "text" : "password"} />
      <Button
        flat
        className="button-show-password"
        label={<Icon width="18px" height="18px" />}
        onClick={() => setShowPassowrd(!showPassword)}
      />
    </div>
  );
};

export default PasswordInput;
