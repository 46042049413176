import styled from "styled-components";
import ProgressBar from "./component";
import { colors } from "utils";

export default styled(ProgressBar)`
  .progress-bar-container {
    background-color: ${colors.SecondaryGrey10};
    border-radius: 50px;
    margin: 16px;

    .progress-bar-fill {
      width: ${(props) => props.progress * 100}%;
      background-color: ${colors.SecondarySuccess100};
      border-radius: inherit;
      text-align: right;

      span {
        padding: 8px;
        color: ${colors.white};
        font-weight: "bold";
      }
    }
  }
`;
