import React from "react";
import { useLocation } from "react-router-dom";
import domain from "appDomain"
import ItemLink from "../ItemLink";
import Header from "../Header";
import { Logout } from "../../Icons";
import { useTranslation } from "react-i18next";

const SideBar = ({ className: styledClassName, navRoutes = [], ...props }) => {
  let location = useLocation();
  const { signOut } = domain.auth.hooks.useSignOut()
  const { t } = useTranslation();

  return (
    <nav className={`${styledClassName} sidebar`} {...props}>
      <Header />
      <ul className="sidebar-list">
        {(navRoutes || [])?.map(({ routeName, route, Icon, showIcon }, key) => (
          <li key={key}>
            <ItemLink
              route={route}
              routeName={t(`Navigation:${routeName}`)}
              Icon={Icon}
              showIcon={showIcon}
              isActive={(location.pathname || "").includes(route)}
            />
          </li>
        ))}
      </ul>
      <footer className="nav-footer">
        <ItemLink to="/" routeName={t(`Navigation:logout`)} Icon={Logout} onClick={signOut} />
      </footer>
    </nav>
  );
};

export default SideBar;
