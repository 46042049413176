const KEY_CODE = {
    ENTER: "Enter",
    ESCAPE:"Escape",
    SPACE:" ",
    BACKSPACE: "Backspace",
    ARROW_UP: "ArrowUp",
    ARROW_DOWN: "ArrowDown",
    ARROW_LEFT: "ArrowLeft",
    ARROW_RIGHT: "ArrowRight",
}

export default KEY_CODE