import React, { useState, useCallback, useEffect } from "react";
import FilterSection from "components/FilterSection";
import { Input } from "components/FormComponents";
import Button from "../Button";
import { Close } from "components/Icons";
import { KEY_CODE, colors, useOnKeyPressListener } from "utils";
import debounce from "lodash.debounce";

const SearchBar = ({
  className: styledClassName = "",
  onChange = null,
  setSearchText = null,
  filterItems = null,
  initialValue = "",
  placeholder = "Search...",
}) => {
  const [value, setValue] = useState(initialValue);

  const handleSearchText = ({ name, value }, e) => {
    setValue(value);
  };

  const delayedQuery = useCallback(
    debounce(() => {
      setSearchText(value);
    }, 500),
    [value]
  );

  const handleCleanSearchBardText = () => {
    setValue("");
    setSearchText && setSearchText("");
  };

  useEffect(() => {
    delayedQuery();

    return delayedQuery.cancel;
  }, [value]);

  useOnKeyPressListener({
    expectedKeys: [KEY_CODE.ENTER],
    action: () => setSearchText(value),
  });

  return (
    <div className={`${styledClassName} searchbar-container`}>
      <div className="searchbar-wrapper">
        <Input
          className="searchbar-input"
          placeholder={placeholder}
          name="searchText"
          onChange={handleSearchText}
          showLabel={false}
          value={value}
          isControlled
        />
        {value != "" && (
          <Button
            className="searchbar-button"
            label={
              <Close
                width="16px"
                height="16px"
                currentColor={colors.PrimaryBlack}
              />
            }
            onClick={handleCleanSearchBardText}
          />
        )}
      </div>
      {filterItems?.length > 0 && (
        <FilterSection className="filters" filterItems={filterItems} />
      )}
    </div>
  );
};

export default SearchBar;
