import React, { useState } from "react";
import { Auth } from "aws-amplify";
import analytics from "../../../analytics";
import useForgotPassword from "./useForgotPassword";
import { removeErrorFromErrors, ValidateForm } from "utils";
import VALIDATION_CONFIG from "./VALIDATION_CONFIG";
import { useCustomToast } from "components/Toast";
import { useTranslation } from "react-i18next";
import domain from "appDomain";

const useSignUp = () => {
  const [contactName, setContactName] = useState();
  const [telephone, setTelephone] = useState();
  const [terms, setTerms] = useState(false);
  const [communications, setCommunications] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUserFormValid, setIsUserFormValid] = useState(false);
  const [isCompanyFormValid, setIsCompanyFormValid] = useState(false);

  const {
    values: { password, username, code },
    errors: forgotPasswordErrors,
    loading: forgotPasswordLoading,
    waitingForCode,
    setWaitingForCode,
    handleSubmit: handleForgotPasswordSubmit,
    handleChange: handleForgotPasswordChange,
  } = useForgotPassword();

  const {
    values: { companyName, administrativeCode, billingEmail },
    errors: errorsCreateCompany,
    loading: loadingCreateCompany,
    handleSubmit: handleCreateCompany,
    handleChange: handleCreateCompanyChange,
  } = domain.company.hooks.useMyCompanyForm({ username });

  const handleForgotPasswordCodeSubmit = async () => {
    await handleForgotPasswordSubmit({ handleCreateCompany });
  };
  const { t } = useTranslation();

  const { notify } = useCustomToast();

  const handleSignUp = async () => {
    analytics.methods.track(
      analytics.events.auth.signupSubmitted({
        username,
        contactName,
        telephone,
      })
    );
    // console.log("SignUpSubmitted event sent")
    setLoading(true);
    setErrors({});
    try {
      const user = await Auth.signUp({
        username: username,
        email: username,
        password: password,
        name: contactName,
        attributes: {
          "custom:username": contactName,
          "custom:terms": terms.toString(),
          "custom:communications": communications.toString(),
          "custom:telephone": telephone,
        },
      });

      await Auth.signIn(username, password);
      await handleCreateCompany();
      await analytics.methods.identifyByUser({ user });
      analytics.methods.track(analytics.events.auth.signupSucceed());
    } catch (e) {
      analytics.methods.track(analytics.events.auth.signupError());
      // console.log("SignupError event sent")
      if (e["code"] === "UsernameExistsException") {
        await Auth.forgotPassword(username);
        setWaitingForCode(e["code"]);
        notify({
          title: t("Auth:code_sent_notify_title"),
          message: t("Auth:code_sent_notify_message"),
        });
      } else {
        setErrors({ request: e["code"] });
      }
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handlerByName = {
    contactName: setContactName,
    telephone: setTelephone,
    terms: setTerms,
    communications: setCommunications,
  };

  const handlerToPropagateByName = {
    username: handleForgotPasswordChange,
    code: handleForgotPasswordChange,
    password: handleForgotPasswordChange,
    companyName: handleCreateCompanyChange,
    administrativeCode: handleCreateCompanyChange,
    billingEmail: handleCreateCompanyChange,
  };
  const handleChange = ({ name, value }) => {
    const handler = handlerByName[name];
    if (handler) handler(value);

    const handlerToPropagate = handlerToPropagateByName[name];
    if (handlerToPropagate) handlerToPropagate({ name, value });

    const newErrors = removeErrorFromErrors({
      errors: errors,
      errorKey: name,
    });

    setErrors(newErrors);
  };

  const validateUserData = () => {
    const { isValid, errors } = ValidateForm({
      ...VALIDATION_CONFIG,
    })({
      username,
      password,
      telephone,
      contactName,
    });

    setIsUserFormValid(isValid);
    setErrors(errors);

    if (isValid) {
      analytics.methods.track(
        analytics.events.auth.signupContactSubmitted({
          username,
          contactName,
          telephone,
        })
      );
    }
  };

  const validateCompanyData = () => {
    const { isValid, errors } = ValidateForm({
      ...VALIDATION_CONFIG,
    })({
      administrativeCode,
      billingEmail,
      companyName,
      terms,
    });
    setIsCompanyFormValid(isValid);
    setErrors(errors);

    if (isValid) handleSignUp();
  };

  return {
    values: {
      username,
      password,
      contactName,
      telephone,
      administrativeCode,
      billingEmail,
      terms,
      companyName,
      communications,
    },
    errors: { ...errors, ...forgotPasswordErrors, ...errorsCreateCompany },
    loading,
    waitingForCode,
    handleSubmit: waitingForCode
      ? handleForgotPasswordCodeSubmit
      : handleSignUp,
    handleChange,
    validateUserData,
    validateCompanyData,
    isCompanyFormValid,
    isUserFormValid,
  };
};
export default useSignUp;
