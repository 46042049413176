import styled from "styled-components";
import PasswordInput from "./component";

export default styled(PasswordInput)`
  position: relative;
  .button-show-password {
    position: absolute;
    right: 1em;
    top: 4em;
    height: 1.5em;
    width: 1.5em;
    background: none;
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
