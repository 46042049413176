import React from "react";

const ProgressBar = ({ className: styledClassName, progress }) => {
  return (
    <div className={styledClassName}>
      <div className="progress-bar-container">
        <div className="progress-bar-fill">
          <span>{`${progress * 100}%`}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
