import React from "react"

const Video = ({ className: styledClassName, url, width }) => (
    <div className={`${styledClassName} video-container`}>
        <video key={url} className="video" width={width || "100%"} height="auto" controls>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
)

export default Video
