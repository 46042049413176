import React, { useState } from "react";
import domain from "appDomain";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { colors } from "utils";
import API from "api";
import { useTranslation } from "react-i18next";

import Button from "components/Button";
import Loader from "components/Loader";

const stripePromise = loadStripe(
  "pk_live_51H86pULFlVxgMVvmuxvX8q7E3wPxA8gj6oTVx4nXAvxxNQhhG2g9N4Ds3SUMccAJRuX82I3chx3AnoSaXW6AbSuh00JpqxSvYK"
);

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      fontWeight: 500,
      color: colors.PrimaryBlue150,

      "::placeholder": {
        color: "#aeb0c9",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};
// "Billing:" is used in order to i18n to recognize which translation to fetch
const pricesInfo = {
  yearly: {
    title: {
      active: "Billing:active_yearly",
      inactive: "Billing:inactive_yearly",
    },
    offer: "Billing:offer_yearly",
    quantity: 60,
    currency: "€",
    frequency: "Billing:frequency_yearly",
    info: ["Billing:billed_yearly", "Billing:per_license"],
    priceId: "price_1I3lrbLFlVxgMVvmDvCFsmBv",
  },
  monthly: {
    title: {
      active: "Billing:active_monthly",
      inactive: "Billing:inactive_monthly",
    },
    quantity: 8,
    currency: "€",
    frequency: "Billing:frequency_monthly",
    info: ["Billing:billed_monthly", "Billing:per_licence"],
    infoB: ["Billing:info_year_monthly"],
    priceId: "price_1I3lsCLFlVxgMVvmAR7dAZfY",
  },
  free: {
    priceId: "price_1I3lyZLFlVxgMVvmfKcyZusz",
  },
};

const Price = ({ type, active, typeSelected, setTypeSelected }) => {
  const { t } = useTranslation();

  const {
    title,
    offer,
    quantity,
    currency,
    frequency,
    info,
    infoB,
  } = pricesInfo[type];
  return (
    <div
      className={`price__container ${
        type === typeSelected ? "active" : "inactive"
      }`}
      onClick={() => setTypeSelected(type)}
    >
      <span className="price__title">
        {t(type === typeSelected ? title.active : title.inactive)}
      </span>
      {offer && <span className="price__offer">{t(offer)}</span>}
      <div className="price_amount_container">
        <div className="price_amount">
          <div className="price_amount_quantity_container">
            <span className="price_amount_quantity">{t(quantity)}</span>
          </div>
          <div className="price_amount_info_container">
            <span className="price_amount_currency">{t(currency)}</span>
            <span className="price_amount_frequency">{t(frequency)}</span>
          </div>
        </div>

        {(info || []).map((item) => (
          <span key={item} className="price_amount_info">
            {t(item)}
          </span>
        ))}
        {(infoB || []).map((item) => (
          <span key={item} className="price_amount_info_bold">
            {t(item)}
          </span>
        ))}
      </div>
    </div>
  );
};

const Summary = ({ typeSelected, company, numberOfInvitations }) => {
  const { t } = useTranslation();

  const { quantity, currency } = pricesInfo[typeSelected];

  const discountQuantity = 300;

  const discountEnabled =
    false &&
    typeSelected === "yearly" &&
    discountQuantity <= numberOfInvitations * quantity;

  return (
    <div className="summary_container">
      <span className="concept">
        <span>
          {t(quantity)}
          {t(currency)}{" "}
          {numberOfInvitations >= 1 ? `x ${numberOfInvitations} licences` : ""}.
        </span>
        <span>
          {numberOfInvitations * quantity}
          {currency}
        </span>
      </span>
      {discountEnabled && (
        <span className="concept">
          <span>{t("Billing:discount")}</span>
          <span>-300€</span>
        </span>
      )}
      <div className="separator" />
      <span className="total">
        <span>
          {numberOfInvitations * quantity -
            (discountEnabled ? discountQuantity : 0)}
          {currency}
        </span>
      </span>
    </div>
  );
};

const BillingForm = ({ styledClassName }) => {
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [typeSelected, setTypeSelected] = useState("yearly");
  //const [paymentMethod, setPaymentMethod] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const {
    company,
    users,
  } = domain.company.hooks.useGetUserCompanyUsersWithRole();
  console.log(users);
  const numberOfInvitations = users.length;

  console.log("company", company);
  const handleDisplayError = (e) => {
    if (e.error) {
      setError(e.error.message);
    } else {
      setError("");
    }
  };
  const handleChange = (e) => {
    handleDisplayError(e);
  };

  const createPaymentMethod = async ({ card }) =>
    stripe.createPaymentMethod({
      type: "card",
      card,
    });

  const handlePaymentThatRequiresCustomerAction = async ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    console.log("AA", subscription);
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        { payment_method: paymentMethodId }
      );

      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer.
        // There's a risk of the customer closing the window before the callback.
        // We recommend setting up webhook endpoints later in this guide.
        return {
          priceId: priceId,
          subscription: subscription,
          invoice: invoice,
          paymentMethodId: paymentMethodId,
        };
      }
    }
    // No customer action needed.
    return { subscription, priceId, paymentMethodId };
  };

  const handleRequiresPaymentMethod = async ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      "requires_payment_method"
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice.payment_intent.status
      );
      throw { error: { message: "Your card was declined." } };
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const onSubscriptionComplete = ({ result }) => {
    console.log({ result });
    if (result.status === "active") {
      console.log("COMPLETE"); // mange backend invoice.paid
    }
  };

  const retryInvoiceWithPaymentMethod = async ({
    customerId,
    paymentMethodId,
    invoiceId,
    priceId,
  }) => {
    const response = await API().post({
      path: "payments/retry-invoice",
      body: {
        customerId,
        paymentMethodId,
        invoiceId,
      },
    });

    const result = await result.json();

    if (result.error) {
      setError(result.error.message);
      return;
    }

    const invoice = result;
    const isRetry = true;

    await handlePaymentThatRequiresCustomerAction({
      invoice,
      paymentMethodId,
      isRetry,
    });
    await onSubscriptionComplete({ result });
  };

  const createSubscription = async ({ paymentMethod }) => {
    const priceId = pricesInfo[typeSelected].priceId;
    const paymentMethodId = paymentMethod.id;
    const resultResponse = await API().post({
      path: "payments/create-subscription",
      body: {
        stripeCustomerId: company.stripe_customer_id,
        paymentMethodId,
        priceId,
        quantity: numberOfInvitations,
      },
    });

    const { result } = await resultResponse.json();
    console.log("result", result);

    if (result.error) {
      setError(result.error.message);
      return;
    }

    const subscription = result;

    console.log({
      subscription,
    });

    // 3d secure handlePaymentThatRequiresCustomerAction
    await handlePaymentThatRequiresCustomerAction({
      subscription,
      priceId,
      paymentMethodId,
    });
    // payment fail
    await handleRequiresPaymentMethod({
      subscription,
      paymentMethodId,
      priceId,
    });

    onSubscriptionComplete({ result });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    try {
      const {
        paymentMethod = false,
        error = false,
      } = await createPaymentMethod({
        card: elements.getElement(CardNumberElement),
      });
      if (paymentMethod) {
        await createSubscription({ paymentMethod });
      }
      if (error) {
        setError(error.message);
      }
    } catch (e) {
      console.log("Error global", e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styledClassName}>
      <h1>{t("Billing:select_payment_plan_title")}</h1>
      <p>{t("Billing:select_payment_plan_subtitle")}</p>

      <div className="prices-container">
        <Price
          type="monthly"
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
        />
        <Price
          type="yearly"
          typeSelected={typeSelected}
          setTypeSelected={setTypeSelected}
        />
      </div>

      {true && stripe !== null && (
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="payment_form">
            <h3>{t("Billing:card_details")}</h3>
            {error && <p className="error">{error}</p>}
            <label>
              {t("Billing:card_number")}
              <CardNumberElement
                onChange={handleChange}
                options={CARD_ELEMENT_OPTIONS}
              />
            </label>

            <div className="payment_form_second_block">
              <div className="payment_form_second_exp">
                <label>
                  {t("Billing:expiration_date")}
                  <CardExpiryElement
                    onChange={handleChange}
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </label>
              </div>
              <div className="payment_form_second_cvc">
                <label>
                  {t("Billing:CVC")}
                  <CardCvcElement
                    onChange={handleChange}
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </label>
              </div>
              <div className="payment_form_second_spacer" />
            </div>
            <img
              className="stripe_logo"
              src={"/assets/images/stripe_logos_2.png"}
              alt="wannalisn"
            />
          </div>

          <Summary
            company={company}
            numberOfInvitations={numberOfInvitations}
            typeSelected={typeSelected}
          />

          {isLoading && <Loader />}

          <div className="button_container">
            <Button
              type="primary"
              label="Subscribe"
              customClassName="submit"
              onClick={handleSubmit}
            />
          </div>
        </form>
      )}

      {false && (
        <Button
          type="ghostBlack"
          label="Cancel"
          flat={true}
          customClassName="cancel"
        />
      )}
      <button className="easter" onClick={() => setTypeSelected("free")}>
        a
      </button>
    </div>
  );
};

const Billing = ({ className: styledClassName }) => (
  <Elements stripe={stripePromise}>
    <BillingForm styledClassName={styledClassName} />
  </Elements>
);

export default Billing;
