import React, { useState, useEffect } from "react";
import domain from "appDomain";
import API from "api";

import { refreshSession } from "apollo/client";

const useMyCompanyForm = ({ onSuccess = null, username } = {}) => {
  const { company } = domain.company.hooks.useGetUserCompany();
  const [companyId, setCompanyId] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [administrativeCode, setAdministrativeCode] = useState();
  const [billingEmail, setBillingEmail] = useState();
  const [submitLabel, setSubmitLabelText] = useState("Register");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    return {
      isValid: true,
      errors: {},
    };
  };
  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});
    try {
      const { isValid, errors } = validate();
      if (isValid) {
        const requestData = {
          path: "companies",
          body: {
            id: companyId,
            company_name: companyName,
            administrative_code: administrativeCode,
            billing_email: billingEmail || username,
          },
        };
        var response = {};
        if (companyId) {
          response = await API().patch(requestData);
        } else {
          response = await API().put(requestData);
        }
        if (response.status === 200) {
          await refreshSession();
          onSuccess?.();
        }
      } else {
        setErrors(errors);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  };

  const handlerByName = {
    companyName: setCompanyName,
    administrativeCode: setAdministrativeCode,
    billingEmail: setBillingEmail,
  };
  const handleChange = ({ name, value }) => {
    const handler = handlerByName[name];
    if (handler) handler(value);
  };

  useEffect(() => {
    if (company) {
      setAdministrativeCode(company.administrative_code);
      setBillingEmail(company.billing_email);
      setCompanyName(company.company_name);
      setCompanyId(company.id);
      setSubmitLabelText("Update");
    }
  }, [company]);

  return {
    values: {
      companyName,
      administrativeCode,
      billingEmail: billingEmail || username,
      submitLabel,
    },
    errors,
    loading,
    handleSubmit,
    handleChange,
  };
};
export default useMyCompanyForm;
