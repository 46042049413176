import styled from "styled-components"
import Checkbox from "./component"
import { colors } from "../../../utils"

export default styled(Checkbox)`
    &,
    &:focus,
    &:active {
        outline: none;
        border: none;
    }

    height: 50;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 12px 8px;
    background: ${colors.white};
    font-weight: bold;
    
    .label {
        font-size: 16px;
        color: ${colors.PrimaryBlack};
    }

    &.primary {
        background: ${colors.PrimaryMagenta100};

        .label {
            color: ${colors.white};
        }
    }

    &.transparentMagenta {
        background: ${colors.white};
        border: 1px solid ${colors.PrimaryMagenta100};

        .label {
            color: ${colors.PrimaryMagenta100};
        }
    }

    &.transparent {
        background: ${colors.white};
        border: 1px solid ${colors.lightBlueGrey};

        .label {
            color: ${colors.PrimaryBlack};
        }
    }

    &.transparentBlue {
        border: 1px solid ${colors.PrimaryBlue100};
        background: ${colors.white};
        .label {
            color: ${colors.PrimaryBlue100};
        }
    }

    &.secondary {
        background: ${colors.white};
        border: 1px solid ${colors.white};

        .label {
            color: ${colors.PrimaryBlack};
        }
    }
    &.ghost {
        .label {
            color: ${colors.PrimaryMagenta100};
        }
    }

    &.ghostBlack {
        .label {
            color: ${colors.SecondaryGrey50};
        }
    }

    &.ghostGray {
        background: ${colors.SecondaryGrey30};

        .label {
            color: ${colors.white};
        }
    }

    &:hover {
        cursor: pointer;
    }

    &.elevation-0 {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
    }

    &.elevation-1 {
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    }

    &.elevation-2 {
        box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
    }

    &.elevation-3 {
        box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
    }

    &.elevation-4 {
        box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 20px 15px 0px rgba(0, 0, 0, 0.25);
    }

    &.elevation-5 {
        box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
        -webkit-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.25);
    }
`
