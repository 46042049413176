import * as React from "react";

function SvgMoreOptions(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <rect
          width={24}
          height={24}
          fill={props.currentColor || "#5E698A"}
          opacity={0.299}
          rx={4}
        />
        <circle cx={5} cy={12} r={2} fill={props.currentColor || "#5E698A"} />
        <circle cx={12} cy={12} r={2} fill={props.currentColor || "#5E698A"} />
        <circle cx={19} cy={12} r={2} fill={props.currentColor || "#5E698A"} />
      </g>
    </svg>
  );
}

export default SvgMoreOptions;
