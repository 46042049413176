import React, { useRef, useState } from "react";

const InputCheck = ({
  className: styledClassName,
  initialvalue,
  onChange,
  label,
  name,
  error,
  disabled,
  checked = false,
}) => {
  const inputEl = useRef(null);
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnclick = () => {
    if (inputEl.current) inputEl.current.click();
  };

  const handleOnChange = ({ name, value }) => {
    setIsChecked(value);
    onChange && onChange({ name, value });
  };
  return (
    <div className={`${styledClassName} input-holder`}>
      <div className="input-wrapper">
        <input
          onChange={(e) => handleOnChange({ name, value: e.target.checked })}
          id={name}
          type="checkbox"
          defaultChecked={initialvalue}
          ref={inputEl}
          className="checkbox"
          name={name}
          disabled={disabled}
          checked={checked}
        />

        <span
          className={`customcheckbox ${isChecked ? "checked" : ""}`}
          onClick={handleOnclick}
        >
          {isChecked && (
            <img alt="checkbox fill" src="../assets/images/checkboxFill.png" />
          )}
        </span>

        <span className="label" onClick={handleOnclick}>
          {" "}
          {label}
        </span>
      </div>

      {error && (
        <div>
          <p className="error_feedback">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputCheck;
