import React from "react"
import { FilterItem } from "./components"

const FilterSection = ({
    className: styledClassName = "",
    filterItems = [],
}) => {
    return (
        <div className={`${styledClassName} filter-section`}>
            {(filterItems || []).map((filterItem, key) => (
                <FilterItem key={`filter-item-${key}`} {...filterItem} />
            ))}
        </div>
    )
}

export default FilterSection
