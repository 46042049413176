import styled from "styled-components";
import Terms from "./component";

export default styled(Terms)`
  padding-top: 20px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 80vh;
  max-width: 80vw;

  .padded {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;
