import React from "react";
import  {SideBar as Nav }from "components/SideBar";

const Dashboard = ({
    className: styledClassName = "",
    Header = null,
    Main = null,
    Footer = null,
    navRoutes = [],
}) => {
    return (
        <div className={`${styledClassName} dashboard-container`}>
            <div className="grid-container">
                <header className="header">{Header}</header>
                <aside className="sidenav aside">
                    <Nav className="navbar" navRoutes={navRoutes} />
                </aside>
                <main className="main">{Main}</main>
                <footer className="footer">{Footer}</footer>
            </div>
        </div>
    );
};

export default Dashboard;
