import React, { useEffect, useState } from "react";
import { utils } from "infrastructure";
import { useQuery } from "@apollo/client";

const ILIKE_FIELDS = ["email"];
const HAS_KEY_FIELDS = [];

const COMPANY_INVITATIONS_BASE_CONFIG = {
  entity: "company_invitations",
  fields: [
    "id",
    "email",
    "expiration_date",
    "used",
    "company_id",
    "code",
    "aws_id",
    "accepted",
  ],
  searchTextConfig: { ilikeFields: ILIKE_FIELDS, hasKeyFields: [] },
  text: false,
  filters: false,
  limit: 25,
  offset: 0,
  withAggregation: true,
};

const baseConfig = COMPANY_INVITATIONS_BASE_CONFIG;

const useQueryCompanyInvitations = ({
  searchText = "",
  filters = [],
  pagination = {},
  fields = COMPANY_INVITATIONS_BASE_CONFIG.fields,
} = {}) => {
  const {
    loading,
    error,
    data,
  } = utils.queries.hooks.useQueryWithTextSearchAndFilters(useQuery)({
    ...COMPANY_INVITATIONS_BASE_CONFIG,
    ...pagination,
    fields,
    text: searchText,
    filters,
  });

  return { loading, error, data };
};

const useSearchText = () => {
  const [searchText, setSearchText] = useState("");
  return { searchText, setSearchText };
};

//this Filters need to be refactored to useFilters, extract generic types as constants to do ir more reusable
// also the section of filter should have something to enable or disable all the filters
// pass the filterItems as config and make this useFilters something generic as the component(s)
const useCompanyInvitationsFilters = () => {
  const [filterValues, setFilterValues] = useState([]);

  const updateFilters = ({ field, condition, value }) => {
    const filtersCleaned = filterValues.filter(
      ({ field: innerField }) => innerField !== field
    );
    setFilterValues([...filtersCleaned, { field, condition, value }]);
  };

  const filterItems = [];

  return { filterItems, filterValues };
};

const useCompanyInvitations = () => {
  const [numItems, setNumItems] = useState(0);

  const { filterItems, filterValues: filters } = useCompanyInvitationsFilters();
  const { searchText, setSearchText } = useSearchText();

  const {
    paginationValues,
    ...pagination
  } = utils.queries.hooks.usePagination({ numItems, baseConfig });

  const { loading, error, data } = useQueryCompanyInvitations({
    searchText,
    filters,
    pagination: paginationValues,
  });

  useEffect(() => {
    if (!loading && data) {
      setNumItems(
        utils.queries.utils.countFromAggregation({ data, baseConfig })
      );
    }
  }, [data, loading]);

  console.log("data", data);
  return { loading, data, error, filterItems, setSearchText, pagination };
};

export default useCompanyInvitations;
