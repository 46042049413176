import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/client";

const GET_COMPANY_USER_BY_ID = gql`
  query getCompanyUser($company_user_id: uuid!) {
    company_users_view(where: { id: { _eq: $company_user_id } }) {
      id
      name
      email
      profile_img
      role_human_id
    }
  }
`;

const useGetCompanyUserById = ({ userId }) => {
  const [user, setUser] = useState(null)
  const [
    getCompanyUser,
    { loading, error, data: { company_users_view = null } = {} },
  ] = useLazyQuery(GET_COMPANY_USER_BY_ID, {
    variables: {
      company_user_id: userId,
    },
    fetchPolicy: "cache-and-network",
  });


  useEffect(() => {
    if (company_users_view?.[0]) setUser(company_users_view[0])
  }, [company_users_view])


  return {
    getCompanyUser,
    user,
    loading,
    error,
  }
  
};
export default useGetCompanyUserById;