const colors = {
    PrimaryBlack: '#353e59',
    SecondaryGrey50: '#5e698a',
    SecondaryGrey40: '#a6a9b4',
    SecondaryGrey30: '#ced1Db',
    SecondaryGrey20: '#e6e8ed',
    SecondaryGrey10: '#f2f3f6',
    Background: '#f7f7fa',
    PrimaryBlue150: '#2b2f6e',
    PrimaryBlue100: '#3b6cff',
    PrimaryBlue30: '#9db5ff',
    PrimaryBlue20: '#d8e0ff',
    PrimaryBlue10: '#edf2ff',
    PrimaryMagenta150: '#ac253e',
    PrimaryMagenta100: '#ff3d61',
    PrimaryMagenta30: '#ff849a',
    SecondaryAlert100: '#eebb13',
    SecundaryAlert30: '#ffea99',
    SecondarySuccess100: '#30d99e',
    SecondarySuccess30: '#c7f4e4',
    SecondarySuccess150: '#229c71',
    yellowOrange: '#ffb600',
    black: '#000000',
    white: '#ffffff',
    lightBlueGrey: '#ced1db',
    lightishBlueThree: '#3361ff',
}

export default colors