import React from "react";
import { Hub, Auth } from "aws-amplify";

const useSignedIn = () => {
  const [authState, setAuthState] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [user, setUser] = React.useState();

  const getUsers = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUser(user);
        setAuthState("signIn");
      }
    } catch (e) {
      console.log("getuser error", e);
    } finally {
      if (loading) {
        setLoading(false);
      }
    }
  };

  const hubListener = (data) => {
    const event = data.payload.event;
    if (event === "signOut") {
      setAuthState("signOut");
    }
    if (event === "signIn") {
      getUsers();
    }
  };

  React.useEffect(() => {
    getUsers();

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    Hub.listen("auth", hubListener);
    return () => Hub.remove("auth", hubListener);

    // eslint-disable-next-line
  }, []);

  return {
    signedIn: authState === "signIn",
    user,
    loading,
  };
};

export default useSignedIn;
