import Auth from "i18n/en/Auth";
import Date from "i18n/en/Date";
import Errors from "i18n/en/Errors";
import Navigation from "i18n/en/Navigation";
import Users from "i18n/en/Users";
import Pagination from "i18n/en/Pagination";
import Invites from "i18n/en/Invites";
import InvitationsPage from "i18n/en/InvitationsPage";
import Billing from "i18n/en/Billing";

const lang = {
  Auth,
  Date,
  Errors,
  Invites,
  InvitationsPage,
  Users,
  Billing,
  Pagination,
  Users,
};
export default lang;
