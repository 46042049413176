import React from "react";
import Modal from "react-modal";

import { backgroundColors } from "./constants";

const BaseModal = ({
  isOpen = false,
  contentCustomStyle = {},
  overlayCustomStyle = {},
  closeModal = null,
  afterOpenModal = null,
  contentLabel = "Modal",
  children,
  className,
  isTransparent = false,
  ...props
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "min(100vw, 600px)",
      ...contentCustomStyle,
    },
    overlay: {
      backgroundColor: isTransparent
        ? backgroundColors.BlackOpacityDark
        : backgroundColors.PrimaryBlue150,
      ...overlayCustomStyle,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
      {...props}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
