import styled from "styled-components";
import InputSelect from "./component";
import { colors } from "../../../utils";

export default styled(InputSelect)`
  margin: 8px 0px;
  .label {
    display: block;
    color: ${colors.PrimaryBlack};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
    text-align: left;
  }
  .select {
    box-sizing: border-box;
    border-radius: 6px;
    border: solid 1px ${colors.SecondaryGrey30};
    background-color: ${colors.white};
    display: block;
    width: 100%;
    padding: 4px 8px;
    cursor: pointer;
    transition: 300ms all ease-in-out;

    &:hover {
      border: solid 1px ${colors.SecondaryGrey50};
      transition: 300ms all ease-in-out;
    }
  }
`;
