import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import domain from "pages/Invitations/domain";
import SearchBar from "components/SearchBar";
import Button from "components/Button";
import Pagination from "components/Pagination";
//import CompanyUserList from "../Invitations";
import { useTranslation } from "react-i18next";

const InvitationRow = ({ invitation }) => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="item">
        <span>{invitation.id}</span>
      </div>
      <div className="item item-lg">
        <span>{invitation.email}</span>
      </div>
      <div className="item">
        <span>
          {invitation.accepted
            ? t("InvitationsPage:accepted")
            : t("InvitationsPage:pending")}
        </span>
      </div>
    </div>
  );
};

const List = ({ data, error, loading }) => {
  const { t } = useTranslation();

  if (loading) return <p>{t("InvitationsPage:error")}</p>;
  if (error) return <p>{t("InvitationsPage:not_found")}</p>;

  return (
    <div className={`company-user-list-container`}>
      <div className="row">
        <div className="row_head_item item">
          <span>{t("InvitationsPage:id")}</span>
        </div>
        <div className="row_head_item item item-lg">
          <span>{t("InvitationsPage:email")}</span>
        </div>
        <div className="row_head_item item">
          <span>{t("InvitationsPage:state")}</span>
        </div>
      </div>
      {(data?.company_invitations || []).map((invitation, key) => (
        <InvitationRow
          invitation={invitation}
          key={invitation.id}
          index={key}
        />
      ))}
      {data?.company_invitations?.length === 0 && <p>Not found</p>}
    </div>
  );
};

const InvitationsList = ({ className: styledClassName }) => {
  const { t } = useTranslation();
  const {
    loading,
    data,
    error,
    filterItems,
    setSearchText,
    pagination,
  } = domain.hooks.useCompanyInvitations();

  const { url } = useRouteMatch();
  const { push } = useHistory();

  return (
    <div className={`${styledClassName} panel-company-user-container`}>
      <header className="page-div-header">
        <div className="header-with-search">
          <h2>{t("InvitationsPage:invitations")}</h2>
          <SearchBar
            placeholder={t("Users:search_users")}
            filterItems={filterItems}
            setSearchText={setSearchText}
          />
        </div>
      </header>
      <List className="main-list" loading={loading} data={data} error={error} />

      <footer className="page-aside-footer">
        <Pagination pagination={pagination} />
      </footer>
    </div>
  );
};

export default InvitationsList;
