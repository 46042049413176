import styled from "styled-components";
import CompanyUserNotFound from "./component";
import { colors } from "utils";

export default styled(CompanyUserNotFound)`
  padding: 16px;
  color: ${colors.PrimaryBlack};

  .company-user-not-found-feedback-container {
  }
  .company-user-not-found-feedback-title {
    padding-bottom: 8px;
  }
  .company-user-not-found-feedback-text {
    padding-bottom: 16px;
  }

  .button-invite {
  }
`;
