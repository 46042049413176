import Main from "./component";
import styled from "styled-components";
import { colors } from "utils";

export default styled(Main)`
  &.page-main {
    display: grid;
    grid-template-columns: 30% 1fr;
    grid-template-areas: "page-aside page-section";
    grid-template-rows: auto 1fr;
    height: 100%;
  }

  .page-section {
    grid-area: page-section;
    max-height: 100vh;
    overflow-y: scroll;
    background: ${colors.SecondaryGrey10};
  }

  .page-aside-header {
    background: ${colors.white};
    padding: 16px;
    z-index: 0;
    border-bottom: 1px solid ${colors.SecondaryGrey30};
    border-radius: 0px 0px 16px 16px;
  }

  .page-aside {
    display: grid;
    grid-template-rows: 1fr 10fr 1fr;
    grid-area: page-aside;
    height: 100vh;
    overflow-y: hidden;
    border-right: solid 1px rgba(0, 0, 0, 0.1);
    background: ${colors.SecondaryGrey10};

    .main-list {
      width: 100%;
      overflow-y: scroll;
      padding: 16px;
      box-sizing: border-box;
      background: ${colors.SecondaryGrey10};
      ::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }

    .page-aside-footer {
      width: 100%;
      background: ${colors.white};
      border-radius: 16px 16px 0px 0px;
      border-top: 1px solid ${colors.SecondaryGrey30};
    }
  }

  @media (max-width: 900px) {
    &.page-main {
      grid-template-columns: 40% 1fr;
    }
  }

  @media (max-width: 726px) {
  }

  @media (max-width: 480px) {
  }
`;
