import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import Button from "components/Button";
import Pagination from "components/Pagination";
import ProgressBar from "components/ProgressBar";
import { DropFile } from "components/FormComponents";
import MailInput from "./MailInput";
import domain from "appDomain";
import { useTranslation } from "react-i18next";

const csvTemplate = [
  ["mail"],
  ["first-mail@example.com"],
  ["second-mail@example.com"],
  ["third-mail@example.com"],
];

const MailImportList = ({
  className: styledClassName,
  userList,
  setUserList,
  addErrors,
  errors,
  notifyCSVRead,
  progress,
  pagination,
  loading,
}) => {
  const {
    addEntryToUserList,
    updateUserWithKey,
    removeFromUserListByKey,
    onCSVRead,
  } = domain.invites.hooks.useMailCSVImportForm({
    userList,
    setUserList,
    addErrors,
    errors,
    progress,
    notifyCSVRead,
  });
  const {
    buttonMessage = false,
    dropMessage,
    dropIcon = "default",
  } = domain.invites.hooks.useImportUsersDropzoneStatus({
    progress,
    errors,
    userList,
  });
  const { pageSize, currentPage } = pagination;
  const usersToDisplay = userList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const { t } = useTranslation();

  progress = Math.round(progress * 100) / 100;

  useEffect(() => {
    if (userList.length === 0) {
      addEntryToUserList();
    }
  }, [userList]);
  return (
    <div className={styledClassName}>
      <div className="import_list_mails_container">
        <div className="import_list_header">
          <h1>{t("Invites:email")}</h1>

          <Button
            className="ghostBlack"
            label={t("Invites:add_another_email")}
            onClick={addEntryToUserList}
            flat={true}
          />
        </div>
        <div className="import_list_mails">
          {usersToDisplay.map((user, index) => {
            return (
              user && (
                <MailInput
                  key={user.key}
                  user={user}
                  index={index + (currentPage - 1) * pageSize + 1}
                  updateUserWithKey={updateUserWithKey}
                  removeFromUserListByKey={removeFromUserListByKey}
                />
              )
            );
          })}
        </div>
        {pagination.maxPages > 0 && <Pagination pagination={pagination} />}
      </div>
      <div className="import_list_input_drop_container">
        <div className="import_list_dropzone">
          <DropFile
            onCSVRead={onCSVRead}
            fileExtension=".csv"
            dropMessage={dropMessage}
            buttonMessage={buttonMessage}
            errors={errors}
            progress={progress}
            icon={dropIcon}
            loading={loading}
          />
          <div></div>

          {progress > 0 && progress < 1 && <ProgressBar progress={progress} />}
        </div>

        <div className="template">
          <CSVLink
            data={csvTemplate}
            enclosingCharacter={""}
            filename={"wannalisn_mail_template.csv"}
          >
            <span>{t("Invites:download_csv_template")}</span>
          </CSVLink>
        </div>
      </div>
    </div>
  );
};

export default MailImportList;
