import styled from "styled-components";
import UserTracking from "./component";
import { colors } from "utils";

export default styled(UserTracking)`
  border-radius: 4px;
  background: ${colors.white};
  padding: 16px 16px 32px;

  .title-container {
    padding-bottom: 16px;

    .title {
      color: ${colors.PrimaryBlack};
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
  }
  .react-calendar-heatmap {
    max-height: 150px;

    .color-scale-1 {
      fill: ${colors.PrimaryMagenta30};
    }
    .color-scale-2 {
      fill: ${colors.PrimaryMagenta100};
    }
    .color-scale-3 {
      fill: ${colors.PrimaryMagenta150};
    }
  }
`;
