import { useState } from "react";
import { utils } from "infrastructure";
import API from "api";
import domain from "appDomain";

const MAILS_PER_REQUEST = 25;

const putUserPackage = async (usersToInvite) => {
  const requestData = {
    path: "companies/users",
    body: {
      usersToInvite,
    },
  };
  const response = await API().put(requestData);
  return response.json();
};

const sendUserPackages = async (
  userPackagesToSend,
  setProgress,
  userListLength
) => {
  const userListResponse = [];
  let count = 0;
  const processor = await domain.utils.sequentialPromiseProcessor(
    (_, item) => putUserPackage(item),
    (response) =>
      response.forEach((user) => {
        count++;
        userListResponse.push(user);
        setProgress(count / userListLength);
      })
  );
  await processor(userPackagesToSend);
  return userListResponse;
};

const useImportUsersForm = () => {
  const [userList, setUserList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  const { mailErrorChecker } = domain.invites.hooks.useMailValidator();
  const pagination = utils.queries.hooks.usePagination({
    numItems: userList.length,
    baseConfig: { limit: 5, offset: 0 },
    rowText: "mails",
  });

  const validate = (userList) => {
    let hasInvalidMails = false;
    const checkedUserList = userList.map((user) => {
      const error = mailErrorChecker(user.mail);
      if (error) {
        user.error = error;
        hasInvalidMails = true;
      } else {
        delete user.error;
      }
      return user;
    });
    if (hasInvalidMails) {
      const newUserList = [...checkedUserList].sort((userA, userB) =>
        userA.hasOwnProperty("error")
          ? -1
          : userB.hasOwnProperty("error")
          ? 1
          : 0
      );
      setUserList(newUserList);
      setErrors(["Invites:error_in_at_least_one_mail"]);
    }
    return !hasInvalidMails;
  };
  const handleSubmit = async () => {
    setLoading(true);
    setErrors([]);
    try {
      const userListFiltered = userList.filter((user) => !!user.mail);
      const isValid = validate(userListFiltered);
      if (isValid) {
        let userPackagesToSend = [];
        for (let i = 0; i < userListFiltered.length; i += MAILS_PER_REQUEST) {
          userPackagesToSend.push(
            userListFiltered.slice(i, i + MAILS_PER_REQUEST)
          );
        }
        const newUserList = await sendUserPackages(
          userPackagesToSend,
          setProgress,
          userListFiltered.length
        );
        setUserList(newUserList);
      }
    } catch (e) {
      console.log("error", e);
      setErrors(["Invites:error_server_unavailable"]);
    } finally {
      setLoading(false);
    }
  };

  const addErrors = (errorList) => {
    setErrors([...errors, ...errorList]);
  };

  const notifyCSVRead = () => {
    setProgress(-1);
    setErrors([]);
  };

  return {
    userList,
    setUserList,
    addErrors,
    errors,
    progress,
    notifyCSVRead,
    loading: loading || (progress && progress < 1),
    handleSubmit,
    pagination,
  };
};
export default useImportUsersForm;
