import styled from "styled-components";
import Input from "./component";
import { colors } from "../../../utils";

export default styled(Input)`
  text-align: left;
  .label {
    width: 100%;
    display: block;
    color: ${colors.PrimaryBlack};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 4px;
  }

  input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-radius: 6px;

    &:focus {
      outline: none;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.SecondaryGrey40};
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${colors.SecondaryGrey40};
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${colors.SecondaryGrey40};
    }
  }

  .simple-input {
    display: block;
    background: ${colors.SecondaryGrey10};
    padding: 12px 8px;
    font-size: 18px;
  }
  .simple-input:focus {
    background: ${colors.PrimaryBlue10};
  }

  input::-webkit-input-placeholder,
  input:-ms-input-placeholder,
  input::placeholder {
    color: ${colors.SecondaryGrey40};
    font-size: 18px;
  }

  &.input-holder {
    display: flex;
  }

  &.input-error {
    .simple-input {
      border: 1px solid ${colors.PrimaryMagenta100};
    }
  }

  .error_feedback {
    color: ${colors.PrimaryMagenta100};
    font-size: 12px;
    padding: 8px 0px;
  }
`;
