import styled from "styled-components";
import InputCheck from "./component";
import { colors } from "../../../utils";

export default styled(InputCheck)`
  .input-wrapper {
    width: 100%;
    display: flex;
  }

  .checkbox {
    display: none;
  }

  .customcheckbox {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    background: ${colors.SecondaryGrey20};

    img {
      width: 24px;
      height: 24px;
    }

    &.checked {
      background: ${colors.SecondaryGrey50};
    }
  }

  .label {
    padding-left: 8px;
    color: ${colors.PrimaryBlack};
    font-size: 16px;
    font-weight: bold;
  }

  .error_feedback {
    color: ${colors.PrimaryMagenta100};
    font-size: 12px;
    padding: 0px 4px;
  }
`;
