import { useRef } from "react";
import domain from "appDomain";

const useMailCSVImportForm = ({
  userList,
  setUserList,
  addErrors,
  notifyCSVRead,
}) => {
  const { mailErrorChecker } = domain.invites.hooks.useMailValidator();
  const ref = useRef({
    key: 0,
  });

  const addEntryToUserList = () => {
    let newUserList = [...userList];
    const key = ref.current.key;
    newUserList.unshift({
      key,
      mail: "",
      state: domain.invites.states.NOT_SENT,
    });
    ref.current.key += 1;
    setUserList(newUserList);
    return key;
  };

  const addMultipleEntriesToUserList = (users) => {
    let newUserList = [...userList];
    let hasInvalidMails = false;
    users.forEach((user) => {
      let newUser = {
        key: ref.current.key,
        mail: user.mail,
        state: domain.invites.states.NOT_SENT,
      };
      const error = mailErrorChecker(user.mail);
      if (error) {
        newUser.error = error;
        hasInvalidMails = true;
      }
      newUserList.push(newUser);
      ref.current.key += 1;
    });
    if (hasInvalidMails) {
      newUserList.sort((userA, userB) =>
        userA.hasOwnProperty("error")
          ? -1
          : userB.hasOwnProperty("error")
          ? 1
          : 0
      );
      addErrors(["Invites:error_in_at_least_one_mail"]);
    }
    setUserList(newUserList);
  };

  const removeFromUserListByKey = (key) => {
    let newUserList = userList.filter((user) => user.key !== key);
    setUserList(newUserList);
  };

  const updateUserWithKey = (key, mail) => {
    let newUserList = [...userList];
    let userToUpdate = newUserList.find((user) => user.key === key);
    userToUpdate.mail = mail;
    setUserList(newUserList);
  };

  const onCSVRead = ({ data, errors: csvErrors, meta }) => {
    if (csvErrors.length > 0) {
      addErrors(csvErrors);
      return;
    }
    if (!meta.fields.includes("mail")) {
      addErrors(["Invites:error_wrong_csv_column"]);
      return;
    }

    notifyCSVRead();
    addMultipleEntriesToUserList(data);
  };

  return {
    addEntryToUserList,
    updateUserWithKey,
    removeFromUserListByKey,
    onCSVRead,
  };
};
export default useMailCSVImportForm;
