import styled from "styled-components"
import Dashboard from "./component"

export default styled(Dashboard)`
    .grid-container {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas: "sidenav header" "sidenav main"  "sidenav footer";
    }

    .header {   
        grid-area: header;
    }

    .sidenav {
       grid-area: sidenav;
        height: 100vh;
        width: 150px;

        .navbar {
            width: 150px;
        }
    }

    .main {
        grid-area: main;
    }

    .footer {
        grid-area: footer;
    }
`