import styled from "styled-components";
import FormLoading from "./component";
import { colors } from "utils";

export default styled(FormLoading)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  opacity: 0.8;
  box-sizing: border-box;
  border-radius: 6px;

  .loader {
    min-height: 64px;
    height: 64px;
  }

  .loading-content {
    color: ${colors.PrimaryBlack};
    text-align: center;
    font-size: 0.8em;
  }
`;
