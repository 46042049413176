import React from "react";

import { Input, FormLoading, PasswordInput } from "components/FormComponents";
import Button from "components/Button";

import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import CallMe from "components/CallMe";
import domain from "appDomain";
import { KEY_CODE, useOnKeyPressListener } from "utils";

import GoBack from "components/GoBack";

const SignIn = ({ className: styledClassName }) => {
  const { t, i18n } = useTranslation();

  const {
    errors,
    loading,
    waitingForCode,
    handleSubmit,
    handleChange,
  } = domain.auth.hooks.useForgotPassword();

  useOnKeyPressListener({
    expectedKeys: [KEY_CODE.ENTER],
    action: handleSubmit,
    isEnabled: !loading,
  });

  const {
    code: codeError,
    username: usernameError,
    password: passwordError,
    request: requestError,
  } = errors;

  return (
    <div className={styledClassName}>
      <GoBack />
      <div className="login_modal_container">
        {requestError && (
          <div className="form_feedback">
            <p className="form_feedback_error">{t(`Errors:${requestError}`)}</p>
          </div>
        )}

        {loading && <FormLoading className="recovery-loading-container" />}

        {waitingForCode ? (
          <>
            <div className="code">
              <Trans i18nKey="Auth:code_sent_notify_message" />
            </div>
            <Input
              key="code"
              showLabel={true}
              label={t("Auth:code")}
              placeholder={t("Auth:code")}
              type="text"
              name="code"
              onChange={handleChange}
              error={codeError && t(`Errors:${codeError}`)}
              disabled={loading}
            />

            <PasswordInput
              showLabel={true}
              label={t("Auth:new_password")}
              placeholder={t("Auth:new_password")}
              type="password"
              name="password"
              onChange={handleChange}
              error={passwordError && t(`Errors:${passwordError}`)}
              disabled={loading}
            />
          </>
        ) : (
          <>
            <div className="recovery">
              <Trans i18nKey="Auth:recover_email_address" />
            </div>

            <Input
              key="email"
              showLabel={true}
              label={t("Auth:email_address")}
              placeholder={t("Auth:email_address")}
              type="email"
              name="username"
              onChange={handleChange}
              error={usernameError && t(`Errors:${usernameError}`)}
              disabled={loading}
            />
          </>
        )}

        <Button
          label={t("Auth:login")}
          type="primary"
          className="submit-button"
          onClick={handleSubmit}
        />

        <div className="login__new">
          <Trans i18nKey="Auth:register_now">
            Don’t have an account? <Link to="signup">Register now</Link>
          </Trans>
        </div>
      </div>

      <CallMe />
    </div>
  );
};

export default SignIn;
