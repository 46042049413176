import React, { useState, useEffect } from "react";
import BaseCard from "components/Cards/BaseCard";
import { Close } from "components/Icons";
import { colors } from "utils";
import Button from "components/Button";
import { TOAST_TYPES } from "../constants";

const Toast = ({
  className: styledClassName = "",
  children,
  onCloseToast,
  onOpenToast,
  title = null,
  message = null,
  type = null,
}) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleCloseToast = () => {
    setIsClosing(true);
    onCloseToast?.();
  };

  useEffect(() => {
    onOpenToast?.();
  }, []);

  return (
    <BaseCard
      className={`${styledClassName} ${isClosing ? "closing" : ""} toast-${
        type ? type : TOAST_TYPES.DEFAULT
      }`}
    >
      <span className="toast-close">
        <Button
          className="close-button"
          label={<Close currentColor={colors.PrimaryBlack} />}
          onClick={handleCloseToast}
        />
      </span>
      <div className="toast-contnet">
        {title && <div className="toast-title">{title}</div>}
        {message && <div className="toast-message">{message}</div>}
        {children}
      </div>
    </BaseCard>
  );
};

export default Toast;
