import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import MailImportList from "./components/MailImportList";
import domain from "appDomain";
import { CSVLink } from "react-csv";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const csvTemplate = [
  ["mail"],
  ["first-mail@example.com"],
  ["second-mail@example.com"],
  ["third-mail@example.com"],
];

const ImportUsersForm = ({ className: styledClassName }) => {
  const {
    userList,
    setUserList,
    errors,
    addErrors,
    loading,
    progress,
    handleSubmit,
    notifyCSVRead,
    pagination,
  } = domain.invites.hooks.useImportUsersForm();
  const { t } = useTranslation();

  return (
    <div className={styledClassName}>
      <div className="import_users_header">
        <h1>{t("Invites:form_title")}</h1>

        <p>
          <Trans i18nKey="Invites:form_subtitle">
            Puedes escribir emails uno a uno o importar una lista en CSV
            <CSVLink
              data={csvTemplate}
              enclosingCharacter={""}
              filename={"wannalisn_mail_template"}
            >
              <span>usando esta plantilla</span>
            </CSVLink>
            . Cada email que nos pases, recibirá una invitación para unirse a
            Wannalisn.
          </Trans>
        </p>
      </div>
      <MailImportList
        userList={userList}
        setUserList={setUserList}
        errors={errors}
        progress={progress}
        notifyCSVRead={notifyCSVRead}
        addErrors={addErrors}
        pagination={pagination}
        loading={loading}
      />
      <div className="buttons">
        <Button
          label={t("Invites:submit_button")}
          type="primary"
          onClick={handleSubmit}
        />
        <Link to="/users">
          <p>{t("Invites:cancel")}</p>
        </Link>
      </div>
    </div>
  );
};

export default ImportUsersForm;
