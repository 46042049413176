import styled from "styled-components";
import Toast from "./component";
import { colors } from "utils";

export default styled(Toast)`
  position: absolute;
  max-width: 320px;
  min-height: 64px;
  width: 320px;
  bottom: 16px;
  right: 16px;
  animation-name: onStart;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  background: ${colors.white};
  color: ${colors.PrimaryBlack};

  &.toast-info {
    background: ${colors.white};
  }

  &.toast-error {
      color: ${colors.white};
    background: ${colors.PrimaryMagenta100};
  }

  &.toast-warning {
    background: ${colors.SecondaryAlert100};
  }

  &.toast-success {
    color: ${colors.white};
    background: ${colors.SecondarySuccess100};
  }

  &.closing {
    animation-name: onClose;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    right: -400px;
  }

  .toast-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 8px;
    z-index: 1000;
  }

  .close-button {
    padding: 0px;
    background: none;
  }

  .toast-contnet {
    text-align: left;
  }

  .toast-title {
    font-weight: bold;
    font-size: 16px;
    padding-bottom: 8px;
  }

  .toast-message {
    font-size: 12px;
  }

  @keyframes onStart {
    from {
      right: -400px;
    }
    to {
      right: 16px;
    }
  }

  @keyframes onClose {
    from {
      right: 16px;
    }
    to {
      right: -400px;
    }
  }
`;
