import React, { useEffect } from "react";
import { BaseCard } from "components/Cards";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";

const CompanyUserCard = ({
  className: styledClassName = "",
  companyUser: {
    email = "",
    id = "",
    profile_img = "",
    role_human_id = "",
    name = "",
  } = {},
  onClick = null,
  isActive = false,
  index,
  ...props
}) => {
  const { state } = useLocation();

  useEffect(() => {
    if (index === 0 && !state?.id)
      onClick?.({
        id,
      });
  }, [state]);
  return (
    <BaseCard
      className={`${styledClassName} company-user-card-container ${
        isActive ? "is-active" : ""
      }`}
      onClick={(event) =>
        onClick(
          {
            id,
          },
          event
        )
      }
      {...props}
    >
      <div className="profile-img-container">
        <div className="profile-img-wrapper">
          <img className="profile-img" src={profile_img} />
        </div>
      </div>
      <div className="content-container">
        <p className="content-text content-title">{name}</p>
        <p className="content-text content-subtitle">{email}</p>
      </div>
    </BaseCard>
  );
};

export default CompanyUserCard;
