import React from "react";
import Loader from "components/Loader";

const FormLoading = ({ className: styledClassName = "", copy = "" }) => {
  return (
    <div className={styledClassName}>
      <div className="loading-content">
        {copy != "" && <h1 className="loading-copy">{copy}</h1>}
        <Loader />
      </div>
    </div>
  );
};

export default FormLoading;
