import React, { useState } from "react";
import Button from "components/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Trans } from "react-i18next";

const CompanyUserNotFound = ({ className: styledClassName = "" }) => {
  const { push } = useHistory();
  const handleInviteUserButton = () => push(`/users/new`);

  return (
    <div className={`${styledClassName}`}>
      <div className="company-user-not-found-feedback-container">
        <Trans i18nKey="Users:user_not_found">
          <h2 className="company-user-not-found-feedback-title">Oops!...</h2>
          <p className="company-user-not-found-feedback-text">
            This mate is not in your list. Would you like to invite it?
          </p>
        </Trans>
      </div>

      <Button
        type="primary"
        label={
          <Trans i18nKey="Users:invite_new_user">Invite a new mate!</Trans>
        }
        className="button-invite"
        onClick={handleInviteUserButton}
      />
    </div>
  );
};

export default CompanyUserNotFound;
