const sequentialPromiseProcessor = async (iterator, postProcessor) => {
  return async (items) => {
    return postProcessor(
      await items.reduce(async (promise, item) => {
        const result = await promise;
        if (result) postProcessor(result);
        return iterator(result, item);
      }, Promise.resolve())
    );
  };
};

export default sequentialPromiseProcessor;
