import BaseCard from "./component"
import styled from "styled-components"
import { colors } from "utils"

export default styled(BaseCard)`
    max-width: 100%;
    width:100%;
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: ${colors.white};
    padding: 8px;
`