import React, { useState } from "react";
import Button from "components/Button";
import { MoreOptions } from "components/Icons";
import { useTranslation } from "react-i18next";

const OptionsMenu = ({
  className: styledClassName = "",
  optionList,
  label,
}) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => setIsActive(!isActive);

  return (
    <div className={styledClassName}>
      <Button
        className="action-button"
        label={
          <>
            <span>{label || t("Users:more_options")}</span>
            <MoreOptions />
          </>
        }
        onClick={handleClick}
      />
      {isActive && (
        <div className="option-menu-wrapper">
          {optionList?.length > 0 && (
            <ul className="option-menu-list">
              {(optionList || []).map((option, key) => {
                return (
                  <li
                    key={key}
                    className="option-menu-list-item"
                    onClick={option.action}
                  >
                    <span>{option.name}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default OptionsMenu;
