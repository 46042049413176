import styled from "styled-components"
import Header from "./component"
import { colors } from "../../../utils"

export default styled(Header)`
    height: 100px;
    border-bottom: 0.25px solid ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;

    .brand-wrapper {
        display: grid;
        grid-template-columns: 24px auto;
        align-items: center;
        justify-content: center;
    }

    h2 {
        padding: 0px;
        margin: 0px;
        color: ${colors.white};
        font-size: 16px;
        padding-left: 8px;
    }
`
