import React, { useEffect } from "react";
import { Input, PasswordInput } from "components/FormComponents";
import Button from "components/Button";

import { Link } from "react-router-dom";
import analytics from "../../analytics";

import { useTranslation, Trans } from "react-i18next";
import CallMe from "components/CallMe";
import logo from "./wannalisn_logo.png";
import domain from "appDomain";
import { KEY_CODE, useOnKeyPressListener } from "utils";
import Loading from "pages/Loading";

const SignIn = ({ className: styledClassName }) => {
  const { t, i18n } = useTranslation();

  console.log("i18n", i18n, i18n.language);
  /*
    {t('Auth:hello')} {t('Auth:user', {count: 5})} -> Hola usuarios
  */

  const {
    errors,
    loading,
    handleSubmit,
    handleChange,
  } = domain.auth.hooks.useSignInForm();

  useEffect(() => {
    analytics.methods.track(analytics.events.auth.loginView());
    // console.log("LoginView event sent")
  }, []);

  useOnKeyPressListener({
    expectedKeys: [KEY_CODE.ENTER],
    action: handleSubmit,
    isEnabled: !loading,
  });

  const {
    request: requestError,
    username: usernameError,
    password: passwordError,
  } = errors;

  if (loading) return <Loading />;

  return (
    <div className={styledClassName}>
      <div className="login_modal_container">
        <img className="wannalisn_logo" src={logo} alt="wannalisn" />
        <span className="logo_label">Business</span>
        {requestError && (
          <div className="form_feedback">
            <p>{t(`Errors:${requestError}`)}</p>
          </div>
        )}

        <Input
          autoFocus
          showLabel={true}
          label={t("Auth:email_address")}
          placeholder={t("Auth:email_address")}
          type="email"
          name="username"
          onChange={handleChange}
          error={usernameError && t(`Errors:${usernameError}`)}
        />
        <PasswordInput
          showLabel={true}
          label={t("Auth:password")}
          placeholder={t("Auth:password")}
          type="password"
          name="password"
          onChange={handleChange}
          error={passwordError && t(`Errors:${passwordError}`)}
        />
        <div className="login__forgot">
          <Link to="recover">{t("Auth:forgot_password")}</Link>
        </div>

        <Button label={t("Auth:login")} type="primary" onClick={handleSubmit} />

        <div className="login__new">
          <Trans i18nKey="Auth:register_now">
            Don’t have an account? <Link to="signup">Register now</Link>
          </Trans>
        </div>
      </div>

      <CallMe />
    </div>
  );
};

export default SignIn;
