import React from "react";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
// import 'react-phone-input-2/lib/style.css'

// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'

const InputPhone = ({
  className: styledClassName,
  onChange,
  name,
  label,
  placeholder,
  onKeyUp,
  showLabel = true,
  value = "",
  isControlled = false,
  error = false,
  ...props
}) => {
  return (
    <div className={styledClassName}>
      <PhoneInput
        specialLabel={
          <span accessibilitylabel={label} className="label">
            {" "}
            {label}{" "}
          </span>
        }
        containerClass={`input-holder ${error ? "input-error" : ""}`}
        country="es"
        inputClass="simple-input"
        dropdownClass="flag-input"
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={(phone, country, e, formattedValue) =>
          onChange &&
          onChange({ name, phone, country, value: formattedValue }, e)
        }
        {...(isControlled ? { value } : {})}
        {...props}
      />
      {error && (
        <div>
          <p className="error_feedback">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputPhone;
