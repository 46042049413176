import React from "react";
import Modal from "components/Modal";
import Billing from "components/Billing";

const BillingModal = ({ onSuccess }) => {
  return (
    <Modal
      isOpen={true}
      isTransparent={true}
      contentCustomStyle={{ padding: 0 }}
    >
      <Billing onSuccess={onSuccess} />
    </Modal>
  );
};

export default BillingModal;
