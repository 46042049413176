import React from "react";
import { useQuery, gql } from "@apollo/client";

const getIlikeSearch = ({ text, field }) => `{${field}: {_ilike: "%${text}%"}}`;
const getIlikeSearchs = ({ fields, text }) =>
  (fields || []).map((field) => getIlikeSearch({ field, text })).join(",");

const getHasKeySearch = ({ text, field }) =>
  `{${field}: {_has_key: "${text}"}}`;
const getHasKeySearchs = ({ fields, text }) =>
  (fields || []).map((field) => getHasKeySearch({ field, text })).join(",");

const searchByText = ({ text, searchTextConfig }) =>
  searchTextConfig &&
  ((searchTextConfig.ilikeFields && searchTextConfig.ilikeFields.length > 0) ||
    (searchTextConfig.hasKeyFields && searchTextConfig.hasKeyFields.length > 0))
    ? `
_or: [
    ${[
      ...(searchTextConfig && searchTextConfig.ilikeFields
        ? [getIlikeSearchs({ text, fields: searchTextConfig.ilikeFields })]
        : []),
      ...(searchTextConfig && searchTextConfig.hasKeyFields
        ? [
            getHasKeySearchs({
              text,
              fields: searchTextConfig.hasKeyFields,
            }),
          ]
        : []),
    ].join(",")}
  ]
`
    : "";

const getFilter = ({ field, condition, value }) =>
  `${field}: {${condition}: ${
    typeof value === "string" ? `"${value}"` : value
  }}`;
const getFilters = ({ filters }) => (filters || [])?.map(getFilter).join(",");

const getSearchAndFilters = ({ text, searchTextConfig, filters }) => {
  const searchAndFilters = [
    ...(text ? [searchByText({ text, searchTextConfig })] : []),
    ...(filters ? [getFilters({ filters })] : []),
  ].join(",");

  return searchAndFilters.length > 0 ? searchAndFilters : "";
};

export const aggregateWithTextSearchAndFilters = ({
  entity,
  text,
  searchTextConfig,
  filters,
}) => `
    ${entity}_aggregate (where: {
        ${getSearchAndFilters({ text, searchTextConfig, filters })}
    }){
        aggregate {
          count
        }
      }
`;

export const queryWithTextSearchAndFilters = ({
  entity,
  fields,
  searchTextConfig = {},
  text = false,
  filters = false,
  limit = 10,
  offset = 0,
  withAggregation = false,
} = {}) => gql`{
  ${entity}(
      limit: ${limit},
      offset: ${offset},
      where: {
          ${getSearchAndFilters({ text, searchTextConfig, filters })}
      }
  ) {
    ${fields.map((field) => `${field}`)}
  }
  ${
    withAggregation
      ? aggregateWithTextSearchAndFilters({
          entity,
          text,
          searchTextConfig,
          filters,
        })
      : ""
  }
 
}`;

const useApolloHookWithTextSearchAndFilters = (apolloHook) => ({
  entity = "",
  fields = [],
  searchTextConfig = {},
  text = "",
  filters = [],
  limit = 10,
  offset = 0,
  withAggregation = false,
}) =>
  apolloHook(
    queryWithTextSearchAndFilters({
      entity,
      fields,
      searchTextConfig: text ? searchTextConfig : {},
      text,
      filters,
      limit,
      offset,
      withAggregation,
    }),
    {
      fetchPolicy: "no-cache",
    }
  );

export const useQueryWithTextSearchAndFilters = useApolloHookWithTextSearchAndFilters;
