import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment-timezone";
import numeral from "numeral";

import "moment/locale/es";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl";
import "numeral/locales/es-es";
import "numeral/locales/en-gb";
import "numeral/locales/fr";
import "numeral/locales/nl-nl";

import es from "i18n/es";
import en from "i18n/en";

const resources = {
  en,
  es,
};

const options = {
  order: ["navigator"],
  lookupQuerystring: "lang",
  lookupCookie: 0,
  lookupLocalStorage: 0,
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: [],
};
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    detection: options,
  });

export default i18next;
