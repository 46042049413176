const STATES = {
  NOT_SENT: "NOT_SENT",
  SENT: "SENT",
  ERROR: "ERROR",
  USER_EXISTS: "USER_EXISTS",
};

const SUCCESS_MESSAGES = {
  [STATES.USER_EXISTS]: "Invites:SUCCESS_MESSAGE_USER_EXISTS",
  [STATES.SENT]: "Invites:SUCCESS_MESSAGE_SENT",
};

export default {
  states: STATES,
  successMessages: SUCCESS_MESSAGES,
};
