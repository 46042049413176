import React from "react";
import { useDropzone } from "react-dropzone";
import Button from "components/Button";
import Papa from "papaparse";
import Loader from "components/Loader";

const DropFile = ({
  onCSVRead,
  fileExtension,
  dropMessage,
  buttonMessage,
  icon,
  loading,
}) => {
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: true,
        delimiter: ";",
        complete: onCSVRead,
      });
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: fileExtension,
  });
  return (
    <>
      <div {...getRootProps({ className: "drop-zone" })}>
        <input {...getInputProps()} />
        {loading && icon !== "error" ? (
          <Loader />
        ) : (
          <img
            src={`../assets/images/dropdown_states/${icon}.png`}
            alt={icon}
          />
        )}
        <p className="topText">
          <span className="bigText">{dropMessage}</span>
        </p>
        {buttonMessage && (
          <Button className="ghostBlack" label={buttonMessage} flat={true} />
        )}
      </div>
    </>
  );
};

export default DropFile;
