import React from "react";

export const BaseButton = ({
  label,
  styles,
  status,
  isLoading,
  disabled,
  flat,
  className: styledClassName,
  customClassName = "",
  ...props
}) => (
  <button
    disabled={disabled || isLoading}
    className={`${styledClassName} base-button ${customClassName}`}
    accessibilitylabel={label}
    accessibilityrole="button"
    {...props}
  >
    <span className={`${props.labelClassName || ""} label`}>{label}</span>
  </button>
);

const Button = ({
  flat,
  type,
  isLoading,
  disabled,
  className: styledClassName,
  elevation = 0,
  ...props
}) => {
  return (
    <BaseButton
      isLoading={isLoading}
      disabled={disabled}
      flat={flat}
      className={`${styledClassName} wann-button ${type} elevation-${elevation} ${
        flat ? "flat" : ""
      }`}
      {...props}
    />
  );
};

export default Button;
