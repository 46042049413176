import styled from "styled-components";
import CompanyUserCard from "./component";
import { colors } from "utils";

export default styled(CompanyUserCard)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
  cursor: pointer;
  box-sizing: border-box;
  transition: 300ms all ease-in-out;

  .content-container {
    flex: 1;
    padding-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .content-text {
      text-align: left;
      color: ${colors.PrimaryBlack};
    }

    .content-title {
      font-size: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .content-subtitle {
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .profile-img-container {
    .profile-img-wrapper {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      background: ${colors.SecondaryGrey30};
    }

    .profile-img {
      max-width: 100%;
      height: auto;
    }
  }

  &:hover {
    background: ${colors.PrimaryBlue30};
    transition: 300ms all ease-in-out;
  }

  &.is-active,
  &.is-active:hover {
    background: ${colors.PrimaryBlue100};
    .content-text {
      color: ${colors.white};
    }
  }

  @media only screen and (max-width: 726px) {
    .profile-img-container,
    .content-container {
      width: 100%;
      padding-left: 0px;
    }
  }
`;
