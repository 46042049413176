import React from "react";
import Modal from "components/Modal";
import CompanyInfoForm from "components/CompanyInfoForm"

const CompanyInfoModal = ({onSuccess}) => {
  return (
    <Modal isOpen={true} isTransparent={true}>
      <CompanyInfoForm onSuccess={onSuccess} />
    </Modal>
  );
};

export default CompanyInfoModal;
