import styled from "styled-components";
import media from "styled-media-query";
import { colors } from "utils";

import CallMe from "./component";

export default styled(CallMe)`
  position: fixed;
  right: 16px;
  top: 16px;
  bottom: unset;
  background-color: white;
  padding: 8px;
  text-align: left;
  display: inline-block;
  border-radius: 16px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);

  ${media.lessThan("small")`
    background-color: ${colors.PrimaryBlue150};
    bottom: 16px;
    top: unset;
    color: white;
  `}

`;
