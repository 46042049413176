import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import domain from "pages/ListOfUsers/domain";
import SearchBar from "components/SearchBar";
import Button from "components/Button";
import Pagination from "components/Pagination";
import CompanyUserList from "../CompanyUserList";
import { useTranslation } from "react-i18next";

const PanelCompanyUser = ({ className: styledClassName }) => {
  const { t } = useTranslation();
  const {
    loading,
    data,
    error,
    filterItems,
    setSearchText,
    pagination,
  } = domain.hooks.useCompanyUsers();

  const { url } = useRouteMatch();
  const { push } = useHistory();

  return (
    <aside className={`${styledClassName} panel-company-user-container`}>
      <header className="page-aside-header">
        <Button
          className="button-add-new"
          type="primary"
          onClick={() => push(`${url}/new`)}
          label={t("Users:invite_new_user")}
        />
        <SearchBar
          placeholder={t("Users:search_users")}
          filterItems={filterItems}
          setSearchText={setSearchText}
        />
      </header>
      <CompanyUserList
        className="main-list"
        loading={loading}
        data={data}
        error={error}
      />
      <footer className="page-aside-footer">
        <Pagination pagination={pagination} />
      </footer>
    </aside>
  );
};

export default PanelCompanyUser;
