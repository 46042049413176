import React from "react";
import { Auth } from "aws-amplify";

const useSignOut = () => {
  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  return {
    signOut,
  };
};
export default useSignOut;
