import React from "react";
import { Input } from "components/FormComponents";
import Button from "components/Button";
import domain from "appDomain";

const CompanyInfoForm = ({ className: styledClassName, onSuccess }) => {
  const {
    values,
    errors,
    loading,
    handleSubmit,
    handleChange,
  } = domain.company.hooks.useMyCompanyForm({ onSuccess });

  return (
    <>
      <Input
        autoFocus={true}
        showLabel={true}
        label="Company name"
        placeholder="Company name"
        type="text"
        name="companyName"
        value={values.companyName}
        onChange={handleChange}
      />

      <Input
        showLabel={true}
        label="Administrative code"
        placeholder="Administrative code"
        type="text"
        name="administrativeCode"
        value={values.administrativeCode}
        onChange={handleChange}
      />

      <Input
        showLabel={true}
        label="Billing email"
        placeholder="billin@acme.com"
        type="text"
        name="billingEmail"
        value={values.billingEmail}
        onChange={handleChange}
      />

      <Button
        label={values.submitLabel}
        type="primary"
        onClick={handleSubmit}
      />
    </>
  );
};

export default CompanyInfoForm;
