import React, { useEffect, useState } from "react";
import Modal from "react-modal";

import domain from "appDomain";

import CompanyInfoModal from "./CompanyInfoModal";
import BillingModal from "./BillingModal/component";

Modal.setAppElement("#modals");

const useShouldOpenSomeAutomaticModal = () => {
  /* TODO -> docs to modals http://reactcommunity.org/react-modal/ 
    Check if company is valid -> show modal invalid
    Check company has valid payment data -> show modal payment data
    Check company has  users.len <= 0 || route users/add(maybe this modal shpuld be managed by the users page instead of global reusing this modal) -> show modal import users 

    to do that it is a good idea do a hook which manage if some modal should be opened
  */
  const [modal, setModal] = useState(false);
  const { signedIn } = domain.auth.hooks.useSignedIn();
  const {
    company,
    users,
    refreshUserList: refreshCompanyQuery,
    loading: fetchingCompany,
  } = domain.company.hooks.useGetUserCompanyUsersWithRole();

  const { hasPlan } = domain.company.hooks.useHasPremiumPlan();

  useEffect(() => {
    if (!company && !fetchingCompany && !!company.id) {
      setModal(automaticModalsTypes.companyInfoModal);
      return;
    }

    if (
      company &&
      !fetchingCompany &&
      !!company.id &&
      users.length >= 5 &&
      !hasPlan
    ) {
      setModal(automaticModalsTypes.billingModal);
      return;
    }

    if (company && modal === automaticModalsTypes.companyInfoModal) {
      setModal(false);
      return;
    }
  }, [company, fetchingCompany]);

  const onSuccess = {
    [automaticModalsTypes.companyInfoModal]: () => {
      refreshCompanyQuery();
    },
  };

  if (!signedIn) return false;
  return { modal, onSuccess: onSuccess[modal] };
};

const automaticModalsTypes = {
  companyInfoModal: "companyInfoModal",
  billingModal: "billingModal",
};

const automaticModalsByType = {
  [automaticModalsTypes.companyInfoModal]: CompanyInfoModal,
  [automaticModalsTypes.billingModal]: BillingModal,
};

const Modals = () => {
  const { modal, onSuccess } = useShouldOpenSomeAutomaticModal();
  if (!modal) return <></>;
  const ModalComponent = automaticModalsByType[modal];
  if (!ModalComponent) return <></>;
  return <ModalComponent onSuccess={onSuccess} />;
};

export default Modals;
