import styled from "styled-components";
import media from "styled-media-query";

import SignUp from "./page";
import { colors } from "utils";

export default styled(SignUp)`
  background: ${colors.PrimaryBlue150};
  min-height: 100vh;
  width: 100%;
  display: flex;

  .forms-container {
    max-width: min(560px, 100vw);
    width: 100%;
    margin: 24px auto;
    box-sizing: border-box;
  }

  .login_modal_container {
    position: relative;
    background-color: white;
    padding: 32px;
    text-align: left;
    display: inline-block;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    width: 100%;
    max-width: 400px;
    margin: 16px auto;

    &.disabled {
      opacity: 0.5;
    }

    display: flex;
    flex-direction: column;
    .sign-up-loading-container {
      .loading-content {
        max-width: 80%;
      }
    }

    .waiting-for-code-feedback {
      color: ${colors.PrimaryBlack};
      font-size: 16px;
    }
    ${media.lessThan("small")`
      height: 100vh;
      width: 100vw;
      max-width: unset;
      border-radius: 0;
  `}
    .wannalisn_logo {
      height: inherit;
      width: 100%;
      ${media.lessThan("small")`
        height: auto;
        width: inherit;
        max-width: 80vw;
        `}
    }

    .brand-container {
      max-width: 200px;
      width: 100%;
      margin: auto;
    }
    .logo_label {
      text-align: right;
      font-weight: 400;
      color: ${colors.SecondaryGrey50};
      opacity: 0.5;
      display: block;
    }

    .input-holder {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }

    .login__forgot {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-bottom: 24px;
      margin-top: 4px;
      a {
        color: ${colors.SecondaryGrey50};
      }
    }

    .login__new {
      margin-top: 24px;
      color: ${colors.SecondaryGrey40};

      a {
        color: ${colors.PrimaryMagenta100};
        font-weight: bold;
        text-decoration: none;
      }
    }
    .form-button {
      margin-top: 24px;
    }
    .form_feedback_error {
      color: ${colors.PrimaryMagenta100};
      margin-bottom: 8px;
    }

    .form-title {
      padding-bottom: 16px;
      h2 {
        font-size: 24px;
        color: ${colors.PrimaryBlack};
      }
      p {
        color: ${colors.SecondaryGrey40};
        padding-bottom: 16px;
      }
    }

    .form-section-separator {
      padding: 16px;
    }

    .form_feedback {
      color: ${colors.PrimaryMagenta100};
      margin-bottom: 8px;
    }

    .overlay-disable-form {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: rgba(0, 0, 0, 0.15);
    }

    .modify-button {
      cursor: pointer;
      color: ${colors.SecondaryGrey50};
      padding: 16px 0px 0px 0px;
      transition: 300ms all ease-in-out;
      font-size: 12px;

      &:hover {
        color: ${colors.PrimaryBlue150};
        transition: 300ms all ease-in-out;
      }
    }

    .form-step {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      p {
        color: ${colors.SecondaryGrey40};
        font-weight: bold;
        padding-bottom: 16px;
      }
    }

    .user-contact-name {
      font-size: 16px;
      padding-bottom: 4px;
    }

    .user-email {
      color: ${colors.PrimaryBlue100};
      font-weight: bold;
      padding-bottom: 4px;
    }

    .user-telephone {
      color: ${colors.SecondaryGrey50};
    }

    .go_back {
      margin-top: 24px;

      a {
        color: ${colors.PrimaryMagenta100};
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
`;
